// 葡萄牙   key由 文字中的四个字的拼音组成
export const lang = {

  'guanbi': '關閉',
  "zhuzaochenggong":"鑄造成功",
  'yitijiao': '已提交交易',

  'duankailianjie':'斷開連接',
  'lianjieshiba':'連接失敗',
  'lianjiebeijujie':'連接失敗，連接被拒絕',
  'wanluocuowu':'網絡錯誤',
  'qingqiehuanwang':'網絡錯誤，請切換到主網。',
  'qiehuanwangluo':'切換到主網',
  'pizhunshibai':'準予失敗',
  'pizhunjujie':'準予請求被拒絕',
  'jiaoyishibai':'交易失敗',
  'jiaoyijujie':'交易請求被拒絕',
  'lianjieMetamask':'連接到你的MetaMask錢包',
  'lianjieWalletConnect':'連接到WalletConnect',
  
  "wodeNFT": '我的NFT',
  "lianjieqianbao":"連接錢包",
  "dianjijinru":"點擊進入",
  "lianjiematemask":"連接到你的MetaMask錢包",
  "lianjiewallect":"連接到WalletConnect",
  "zhuzao":"鑄造",
  "luxiantu":"路線圖",
  "tuandui":"團隊",
  "meirixiangliang":"每日限量供應  早買享受優惠",
  "mkcdengdai":" 9000 MKC等待鑄造",
  "dianjizhuzao":"點擊鑄造",
  "guanyumk":"關於MK",

  "about1":"自MK從西天取得真經歸來已是萬年。",
  "about2":"鬥轉星移，世事變遷，地球的主宰者人類向人性解放的道路上越走越遠，人性取代神性，物欲取代禁欲，世俗之樂取代神聖信仰。 人類貪婪的本性令地球萬物生無可戀。 人類間相互仇恨，內卷，戰爭，種族歧視，病毒肆虐……人類文明面臨崩潰的邊緣。",
  "about3": "為了拯救人類，MK幻化為一萬個分身，落入人群中。 這一萬個MK分身入定的天選之子，每日需要接受人性善與惡的考驗，最終方能修成MK。 一萬MK集結，共同守護地球。",
  "about4": "善惡只不過一念之間，MK分身降臨時世間帶來的“一念禪語”，會幫助天選之子們經受住複雜人性的考驗。",
  "about5": "MK天選之子分為五個族群，他們各有人性的弱點與優點：",
  "about6": "軟萌族-擁有一顆不願意長大的童心，厭惡複雜而殘酷的成人社會，回歸單純善良的人性本質。",
  "about7": "街頭族-向社會不公與禁錮發出反抗、叛逆和呐喊，即使深處底層，仍有勇氣觸碰天堂。",
  "about8": "魔幻族-心思細膩、情感豐富、富於幻想，有不少人擁有敏銳的第六感與預知未來的能力。",
  "about9": "機車族-一生狂放不羈愛自由，用冒險的旅程來挑戰自我的極限和心中的勇氣。",
  "about10": "星際族-對於未解的事物都發出求知的欲望，感覺生存在這個世界上就是來探索未來的。",

  "zhuzaoguize":"鑄造規則",
  "fenpeijizhi":"分配機制",
  "baoliu":"保留",
  "1000ge":"1000個",
  "zhuzao":"鑄造",
  "9000ge":"9000個",
  "diyicishifang":"第一次釋放2000個，72天后每週二淩晨3點釋放700個，持續釋放10周。",
  "zhuzaojiage":"鑄造價格",
  "MINTshuliang":"MINT數量",
  "1_72tian":"1-72天",
  "MINTjiage":"MINT價格",
  "73_108tian":"73-108天",
  "109tian":"109天后",
  "zhanghurengoucishu":"一個帳戶認購1次，1次1-3個（最小1個，最大3個）",
  "luxiantu":"路線圖",
  "luxiantu1":"计划根据路线图逐步构建MK Labs元宇宙世界",
  "luxiantu2":"擁有自己的twitter、discord官方媒體矩陣及社群",
  "luxiantu3":"推出MK Oriental潮玩衍生品",
  "luxiantu4":"擁有我們自己的YouTube頻道及Tiktok官方媒體",
  "luxiantu5":"線下潮玩IP聯合巡展，推出限量版T恤、球鞋及其他好物",
  "luxiantu6":"推出MK Boutique--獨家商品/限量版收藏品",
  "luxiantu7":"線下數位藝術品拍賣，在知名都市地標建築物擺設MK雕像",
  "luxiantu8":"聯合知名製作人發佈MK video，組織線下會員沙龍",
  "luxiantu9":"opensea回購，聯合持有MK的知名品牌舉辦系列聯名活動",
  "luxiantu10":"購買專屬的虛擬土地展示元宇宙空間，啟動GameFi開發",
  "luxiantu11":"Gala&Meetups慶典與線下會員沙龍，公佈面向MKC會員空投3個NFT的路線圖",
  "tuanduijieshao":"開發及運營團隊介紹",
  "saicheshou":"賽車手",
  "saicheshou1":"極致旅行探險愛好者！ 一直在路上的修行者",
  "shejishi":"天馬行空的瘋狂設計師",
  "shejishi1":"由於平時極不著調，日子過得很不舒意。 千萬次希望重來一次，終於上帝給了他這個機會，如何把握，何去何從，怎樣才能讓人生不再留遺憾？！",
  "faxingzhe":"Mobile game的最早一批發行者",
  "faxingzhe1":"熱愛法國紅酒、古巴雪茄，是蘇格蘭高地威士卡的忠實粉絲。",
  "jinrongzhuanjia":"區塊鏈科技與金融專家",
  "jinrongzhuanjia1":"寵物愛好者、享受眼鏡王蛇的回頭殺，每天一隻大黃狗為伴。",
  "dukadi":"杜卡迪機車愛好者",
  "dukadi1":"憑藉碼農的經驗一舉成為拉斯維加斯賭場的神走位荷官。",
  "yuanzhuming":"區塊鏈行業高山族",
  "yuanzhuming1":"身穿m65，肩挎ak47，非洲大草原黑白兩道轉換自如",
  "mincheng":"名稱",
  "shifang":"釋放",
  "zhuzao":"鑄造",
  "jiage":"價格",
  "yixuan":"已選",
  "zongji":"總計",
  "yue":"餘額",
  "zhuzaozhong":"鑄造中",
  "yuebuzu":"餘額不足",
  "dengdaiqueren":"等待確認",
  "zhuzaowancheng":"鑄造完成",

  "yaoqinglianjie": '邀請連結',
  "fuzhi": "複製到粘貼板",
  "fuzhichenggong": "複製成功",
  "dakaiqianbao": "打開您的去中心化錢包",
  "lianjiefuzhi": "將網站連結複製後，在錢包瀏覽器中打開進行鑄造"
}
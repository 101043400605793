<template>
  <div id="castnft_h5page">

    <div class="nftInfo">
      <!-- <div class="infoItem">
        <p>{{$t("lang.mincheng")}}</p>
        <p>MKCLUB</p>
      </div> -->
      <div class="infoItem">
        <p>{{$t("lang.shifang")}}</p>
        <p>{{$store.state.NFTInfo.release}}/{{$store.state.NFTInfo.maxSupply}}</p>
      </div>
      <div class="infoItem">
        <p>{{$t("lang.zhuzao")}}</p>
        <p>{{$store.state.NFTInfo.alreadyMint}}/{{$store.state.NFTInfo.release}}</p>
      </div>
      <div class="infoItem">
        <p>{{$t("lang.jiage")}}</p>
        <p>{{($store.state.NFTPrice*1).toFixed(1)}}ETH</p>
      </div>
    </div>
    <div class="mintbg">
      <img src="./../../assets/images/mintbg_phone.png" alt="">
      <div class="inputView">
        <img src="./../../assets/images/miuns.png" alt="" @click="minus">
        <input type="number" readonly v-model="nftNum">
        <img src="./../../assets/images/add.png" alt="" @click="add">
      </div>
    </div>

    

    <p class="balance">{{$t("lang.yue")}}: {{$store.state.ETHBalance}}ETH</p>

    <div class="bottomBtn">
      <div class="left">
        {{$t("lang.yixuan")}}{{nftNum}}/3, {{$t("lang.zongji")}}: <span>{{($store.state.NFTPrice * nftNum).toFixed(1)}}ETH</span>
      </div>
      <div class="right">
        <!-- 余额不足 -->
        <div class="balanceNotMint" v-if="($store.state.NFTPrice * nftNum) > ($store.state.ETHBalance)">
          {{$t("lang.yuebuzu")}}
          <img src="./../../assets/images/lingxing_gray.png" alt="" class="icon">
        </div>
        <!-- 能铸造 -->
        <div v-else>
          <div class="canMint" v-if="buyingStatus!=2" @click="buyNft">
            {{$t("lang.zhuzao")}}
            <img src="./../../assets/images/btnlx.gif" alt="" class="icon">
          </div>

          <div class="Minting" v-if="buyingStatus==2">
            {{$t("lang.zhuzaozhong")}}
            <div class="minting_loading">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
            <img src="./../../assets/images/btnlx.gif" alt="" class="icon">
          </div>
        </div>
       
        <!-- 铸造中 -->
      </div>
    </div>

    <!-- 弹窗  showModal -->
    <PcModal v-if="showModal" @closeModal="closePCmodal" :title="modalTitle">
      <!-- loading -->
      <div slot="content" v-if="modalType == 0" class="buy_succes_modal_h5"><modalLoading :h5="true"/></div>
      <!-- 交易已提交 -->
      <div slot="content" v-if="modalType == 1" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <img src="../../assets/image/arrow_top.png" alt="" style="width:67px;margin:0 auto;">
          <p style="text-align:center;margin-bottom:20px">{{$t("lang.yitijiao")}}</p>
        </div>
        <div class="buy_succes_modal_btn_h5" style="background:#000" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 交易成功 -->
      <!-- <div slot="content" v-if="modalType == 2" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <p>{{$t("lang.rengouchengong")}}</p>
        </div>
        <div class="buy_succes_modal_btn_h5" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div> -->
      <!-- 失败 -->
      <div slot="content" v-if="modalType ==3" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <img src="../../assets/image/error_gray.png" alt="" style="width:67px;margin:0 auto;">
          <p style="text-align:center;margin-bottom:20px">{{$t("lang.jiaoyishibai")}}</p>
        </div>
        <div class="buy_succes_modal_btn_h5" style="background:#000" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
    </PcModal>
    
    <!-- 铸造成功 -->
    <div class="mint_success_modal_h5" v-if="castSuccess">
      <p class="mint_success_num">{{castNftList.length}}</p>
      <p class="mint_success_title">NFT</p>
      <div class="mint_success_item" v-for="(item,index) in castNftList" :key="index+'castNftList'">
        <p>MKC</p>
        <p>#{{item}}</p>
      </div>
      <div class="mint_success_btn" @click="$router.push('/h5mynft')">{{$t("lang.wodeNFT")}}</div>
    </div>

  </div>
</template>
<script>
import axios from 'axios';
import PcModal from './../../components/H5Modal.vue'
import modalLoading from '../../components/loading.vue';
import UmiButton from '../../components/UmiButton.vue';
import LoadingIcon from '../../components/loadingIcon.vue';
// 交易hash
let successOnce = false;
export default {
  name: "detail",
  components: {
    PcModal,
    modalLoading,
    UmiButton,
    LoadingIcon
  },
  data() {
    return {
      nftNum: '1',

      showModal: false, // false
      modalType: 0, // 0 loading, 1 已提交  2 成功 3 失败
      modalTitle: this.$t("lang.dengdaiqueren"),

      buyingStatus: 1, // 1可铸造 2 铸造中 3 铸造完成
      buyingStatusTxt:  this.$t("lang.zhuzao"),

      disbaleBuy: false,
      ETHBalance: 0,

      castNftList: [],
      castSuccess: false,
      canBuyNum: 3,

      intervalObj: null,
      countTime: {
        hour: '--',
        minutes: '--',
        seconds: '--'
      },
      castedNum: 0, // 已铸造数量
      canCastNum: 0, // 可铸造
    };
  },
  computed: {
    
  },
  watch: {
    "$store.state.setNFTInfo" () {
      if(this.$store.state.NFTInfo.alreadyMint!=0 && this.$store.state.NFTInfo.release!=0) {
        this.disbaleBuy = (this.$store.state.NFTInfo.alreadyMint *1  == this.$store.state.NFTInfo.release *1 );
      }
    }
    
  },
  methods: {
    openLinkWallet() {
      this.$emit("openLinkWallet")
    },
    closePCmodal() {
      this.showModal = false;
      this.modalType = 0;
      this.modalTitle = this.$t("lang.dengdaiqueren");
    },
    minus() {
      if(this.nftNum == 1) {
        return 
      }
      this.nftNum = this.nftNum - 1;
    },
    add() {
      if(this.nftNum == this.canBuyNum) {
        return 
      }
      this.nftNum = (this.nftNum * 1) + 1;
    },
    async buyNft() {
      if(this.disbaleBuy) {
        return 
      }
      if(this.buyingStatus != 1) {
        return
      }
      successOnce = false;
      this.showModal = true;
      this.buyingStatus = 2;
      const { NFT_contract,userAddress, NFTList } = this.$store.state;
      const gasPrice  = await this.web3js.eth.getGasPrice();
      
      const num = this.web3js.utils.toWei((this.nftNum * this.$store.state.NFTPrice)+''); //获取当前gas 价格
      
      NFT_contract.methods.mint(this.nftNum*1).send({from: userAddress,gasPrice,value: num })
      .on('transactionHash', (hash)=>{ // 交易hash
        this.modalType = 1;
        this.modalTitle = '';
        !this.showModal && (this.showModal = true);
      })
      .on('confirmation', (confirmationNumber, receipt)=>{ // 
      })
      .on('receipt', async (receipt)=>{ // 交易已广播
        if(!successOnce) {
          successOnce = true;
          let list = [];
          if((this.nftNum*1) > 1) {
            const itemlist = receipt.events.Transfer;
            for(let i = 0; i<itemlist.length ; i++) {
              
              list.push(itemlist[i].returnValues.tokenId)
            }
            
          } else {
            const returnValue = receipt.events.Transfer.returnValues;
            
            list = [
              returnValue.tokenId
            ]
          }
          this.castNftList = list;
          this.castSuccess = true;
          this.showModal = false;
          this.buyingStatus = 3;
          this.buyingStatusTxt = this.$t("lang.zhuzaowancheng");
          this.getInfo();
          this.getBalance();
          this.$store.commit('setNFTList',[...NFTList,...list])
        }
      })
      .on('error',(error, receipt)=>{ // 交易失败
        if(!successOnce) {
          this.modalType = 3;
          this.modalTitle = '';
          this.buyingStatus = 1;
          !this.showModal && (this.showModal = true);
          this.buyingStatusTxt =  this.$t("lang.zhuzao");
        }
      })
    },
    async getInfo() {
      const { NFT_contract } = this.$store.state;
      const maxSupply = await NFT_contract.methods.MAX_MINT_SUPPLY().call();
      const alreadyMint = await NFT_contract.methods.alreadyMintNftCount().call();
      const current = await NFT_contract.methods.currentLeftNft().call();
      const release = await NFT_contract.methods.releaseNft().call();
      this.$store.commit('setNFTInfo', { maxSupply, alreadyMint, current, release  })
    },
    async getBalance() {
      const { userAddress,balance } = this.$store.state;
      const ETHres = await this.web3js.eth.getBalance(userAddress);
      const ETHBalance = this.$utils.mathpow(ETHres,this.$api.ETH_Demical);
      if(balance.ETHBalance == ETHBalance) {
        setTimeout(()=>{this.getBalance()},1000)
        return 
      }
      this.$store.commit('setBalance',{...balance,ETHBalance})
    },
   
  },
  mounted() {
    if(!this.$store.state.userAddress) {
      this.$emit('openLinkWallet');
      return 
    }
    if(this.$store.state.NFTInfo.alreadyMint!=0 && this.$store.state.NFTInfo.release!=0) {
      this.disbaleBuy = (this.$store.state.NFTInfo.alreadyMint *1  == this.$store.state.NFTInfo.release *1 );
    }
  }
};
</script>

<style lang="less">
#castnft_h5page {
  padding-top: 20px;
  .nftInfo {
    width: 100%;
    height: 58px;
    background: #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    .infoItem {
      flex: 1;
      flex-shrink: 0;
      p {
        font-size: 12px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
  .mintbg {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    img {
      width: 100%;
    }
    .inputView {
      position: absolute;
      bottom: 10px;
      left: 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      img {
        width: 30px;
        height: 30px;
      }
      input {
        width: 192px;
        height: 38px;
        background: rgba(255, 255, 255, 0.3);
        margin: 0 10px;
        border: none;
        outline: none;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        text-align: center;
        line-height: 38px;
      }
    }
  }
  
  .balance {
    font-size: 11px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #DDE7FF;
    text-align: center;
    margin-bottom: 42px;
  }

  .bottomBtn {
    display: flex;
    align-items: center;
    margin-bottom: 31px;
    .left {
      flex: 1;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-weight: 600;
      color: #FFFFFF;
      background: rgba(0, 51, 255, 0.5);
      span {
        font-size: 14px;
      }
    }
    .right {
      flex: 1;
      height: 58px;
      font-size: 14px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 58px;
      text-align: left;
      position: relative;
      img {
        position: absolute;
        right: 10px;
        top: -13px;
        width: 86px;
        height: 86px;
      }
      .canMint {
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, #0061FF,#0033FF);
        padding-left: 16px;
        &:active {
          background: linear-gradient(to left, #88ABFF,#5072FF);
        }
      }
      .Minting {
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #004CFF 0%, #7C96FF 100%);
        padding-left: 16px;
        display: flex;
        align-items: center;

        .minting_loading {
          margin-left: 5px;
          text-align: center;
        }
          .minting_loading > div {
            width: 6px;
            height: 6px;
            background-color: #fff;
          
            border-radius: 100%;
            display: inline-block;
            -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
          }
          
          .minting_loading .bounce1 {
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
          }
          
          .minting_loading .bounce2 {
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
          }
          
          @-webkit-keyframes sk-bouncedelay {
            0%, 80%, 100% { -webkit-transform: scale(0) }
            40% { -webkit-transform: scale(1.0) }
          }
          
          @keyframes sk-bouncedelay {
            0%, 80%, 100% { 
              -webkit-transform: scale(0);
              transform: scale(0);
            } 40% { 
              -webkit-transform: scale(1.0);
              transform: scale(1.0);
            }
          }
      }
      .balanceNotMint {
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, rgba(136, 171, 255, 0),rgba(124, 150, 255, 1));
        padding-left: 16px;
      }
    }
  }

}

.buy_succes_modal_h5 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .buy_succes_modal_content_h5 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 26px;
    p {
      font-size: 13px;
      color: #000;
      padding: 0 10px;
      margin-top: 10px;
      text-align: center;
      text-align: center;
    }
  }
  .buy_succes_modal_btn_h5 {
    width: 100%;
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
  }
}

.mint_success_modal_h5 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 91;
  .mint_success_num {
    font-size: 96px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
  }
  .mint_success_title {
    font-size: 19px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 66px;
  }
  .mint_success_item {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    p {
      flex: 1;
      font-size: 19px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      text-align: right;
      word-break: break-all;
      &:last-child {
        margin-left: 65px;
        text-align: left;
      }
    }
  }
  .mint_success_btn {
    width: 240px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: rgba(0, 51, 255, 1);
    font-size: 19px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin-top: 40px;
  }
}
</style>
  
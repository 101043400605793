<template>
  <div id="mynft">
    <div class="nftcontent">
      <div style="height:15rem" v-if="nftList.length==0"></div>
      <div class="nftlistView" v-else>
        <div class="nftlist" v-for="(item,index) in nftList" :key="index" >
        <div class="nftitem">
          <img :src="item.IPFS" alt="">
          <div class="iteminfo">
            <p>MK</p>
            <p>#{{item.id}}</p>
          </div>
        </div>
      </div>
      </div>
      <div class="opensea"><a href="https://opensea.io/account" target="_blank">OPENSEA</a></div>
    </div>
  </div>
</template>
<script>

import modalLoading from "../../components/loadingIcon.vue";
import axios from 'axios';
// 交易hash
export default {
  name: "detail",
  components: {
    modalLoading
  },
  data() {
    return {
      nftList: [],
      loading: true
    };
  },
  computed: {
    
  },
  watch: {
    '$store.state.NFTList'() {
      this.getList();
    }
  },
  methods: {
    async getList() {
      this.loading = true;
      const { NFT_contract } = this.$store.state;
      const list = this.$store.state.NFTList;
      console.log('zzzzz',list)
      const result = []
      try {
        if(list.length) {
          for(let i = 0; i<list.length ; i++) {
            const imgData  = await NFT_contract.methods.tokenURI(list[i]).call();
            const jsonRes = await axios.get(imgData.replace('https://ipfs.io','https://mk.mypinata.cloud'));
            
            result.push({ IPFS: `https://mk.mypinata.cloud/ipfs/${jsonRes.data.image}`, id: list[i]})
            console.log(result)
          }
        }
      } catch (error) {
        
      }
      this.loading = false;
      this.nftList = result;
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<style lang="less">
#mynft {
  padding-bottom: 10rem;
  .nftcontent {
    width: 60rem;
    height: auto;
    margin: 0 auto;
    padding: 4.2rem 0;
    border-bottom: 2px solid #0033FF;
    .nftlistView {
      display: flex;
      flex-wrap: wrap;
      padding: 0  4.4rem;
      padding-right: 3.1rem;
    }
    .nftlist {
      display: flex;
      flex-wrap: wrap;
      
      .nftitem {
        width: 16rem;
        height: 18.2rem;
        margin-right: 1.3rem;
        margin-bottom: 2rem;
        background: url('./../../assets/images/mynftitembg.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 9.66rem;
          height: 9.66rem;
          margin-bottom: 0.8rem;
        }
        .iteminfo {
          width: 9.66rem;
          display: flex;
          justify-content: space-between;
          p {
            font-size: 0.75rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
          }
        }
      }
    }
    .opensea {
      margin: 0 auto;
      margin-top: 5rem;
      width: 20rem;
      height: 3rem;
      background: linear-gradient(270deg, #0061FF 0%, #0033FF 100%);
      line-height: 3rem;
      text-align: center;
      font-size: 1rem;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      a {
        display: block;
        margin: 0;
        width: 100%;
        color: #fff;
        text-decoration: none;
      }
      &:hover {
        background: linear-gradient(270deg, #88ABFF 0%, #5072FF 100%);
      }
    }
  }
}
</style>  
<template>
  <div class="h5_modal" >
    <div class="h5_modal_content">
      <div class="close_modal_h5" @click="close"><img src="./../assets/image/close.png"/></div>
      <p class="title_h5">{{title}}</p>
      <div class="modal_view_h5">
         <slot name="content"></slot>
      </div>
      <div class="btn_h5" v-if="btnText" @click="clickBtn">{{btnText}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "h5_modal",
  props: [ 'title', 'btnText', ],
  data() {
    return {
      
    }
  },
  
  methods: {
    clickBtn() {
      this.$emit('btnClick');
    },
    close() {
      this.$emit('closeModal');
    }
  },
  mounted() {
    
  },
  
};
</script>

<style>

.h5_modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 99;
}
.h5_modal_content {
  width: 100%;
  height: 300px;
  background: #FFFFFF;
  /* border-radius: 20px 20px 0 0; */
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.title_h5 {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  text-align: center;
}
.modal_view_h5 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.btn_h5 {
  width: 100%;
  height: 38px;
  background: linear-gradient(270deg, #0061FF 0%, #0033FF 100%);
  border-radius: 1px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 38px;
  color: #FFFFFF;
}
.close_modal_h5 {
  position: absolute;
  right: 19px;
  top: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close_modal_h5 img {
  width: 15px;
  height: 15px;
}
</style>

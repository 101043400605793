// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/index'
import '@/assets/css/style.styl'
import 'element-ui/lib/theme-chalk/index.css';
import API from './assets/js/API'
import ElementUI from 'element-ui';
import utils from './utils/utils';
 
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

Vue.use(ElementUI);

import animated from 'animate.css'
Vue.use(animated);
// 国际化
import VueI18n from 'vue-i18n'

Vue.use(VueI18n) ;
Vue.prototype.web3js = null;
Vue.prototype.$utils = utils;
Vue.prototype.$api = API;
Vue.config.productionTip = false;
// 根据浏览器的语言来设置本地语言
const locale_lang = (()=>{
  var local = 'en';
  // var lang = navigator.language || navigator.userLanguage;
  // if(lang == 'zh-CN') {
  //   local = 'zh'
  // } else if (lang=='ko') {
  //   local = 'ko'
  // } else if (lang=='ja') {
  //   local = 'ko'
  // } else if (lang == 'zh-TW') {
  //   local = 'zhtw'
  // }
  return local
})();
const i18n = new VueI18n({
  locale: locale_lang,    // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale 
  messages: {
    'zh': require('./lang/zh'),   // 中文语言包
    'en': require('./lang/en'),   // 英文语言包
    'ko': require('./lang/ko'),
    'jp': require('./lang/jp'),
    'zhtw': require('./lang/zhtw'),
  }
})
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

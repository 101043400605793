<template>
  <div id="detailsPage">
    <div class="banner" >
      <img src="./../../assets/images/banner1.png" alt="" class="bannerImg">
      <img src="./../../assets/images/indexbg1.png" alt="" class="bgicon">
    </div>
    <div class="mint_info">
      <div class="mint_bannerView" >
        <div class="mint_banner">
          <div class="left">
            <p>{{$t("lang.meirixiangliang")}}</p>
            <p>{{$t("lang.mkcdengdai")}}</p>
          </div>
          <div class="right">
            <p>{{$t("lang.dianjizhuzao")}}</p>
            <img src="./../../assets/images/btnlx.gif" alt="" @click="goMint">
          </div>
        </div>
      </div>
      <div style="width:60rem;height:4.05rem;border-bottom:1px solid #0033FF;margin: 0 auto"  id="one"></div>
      <!-- 铸造规则 -->
      <div class="ruleview" >
        <div class="ruleContent">
          <div class="ruleTitle">{{$t("lang.zhuzaoguize")}}</div>

          <p>{{$t("lang.fenpeijizhi")}}</p>
          <p>{{$t("lang.baoliu")}}：{{$t("lang.1000ge")}}（#0-#999）</p>
          <p style="margin-bottom:1rem">{{$t("lang.zhuzao")}}：{{$t("lang.9000ge")}}</p>

          <p>{{$t("lang.zhuzaoguize")}}</p>
          <p style="margin-bottom:1rem">{{$t("lang.diyicishifang")}}</p>

          <p>{{$t("lang.zhuzaojiage")}}</p>
          <p>{{$t("lang.1_72tian")}}</p>
          <p>{{$t("lang.MINTjiage")}}：2.0ETH</p>
          <p>{{$t("lang.73_108tian")}}</p>
          <p>{{$t("lang.MINTjiage")}}：2.5ETH</p>
          <p>{{$t("lang.109tian")}}</p>
          <p style="margin-bottom:1rem">{{$t("lang.MINTjiage")}}：3.0ETH</p>

          <p>{{$t("lang.MINTshuliang")}}</p>
          <p style="margin-bottom:2rem">{{$t("lang.zhanghurengoucishu")}}</p>

          <div class="gomintBtn" @click="goMint">
            {{$t("lang.dianjizhuzao")}}
            <img src="./../../assets/images/btnlx.gif" alt="">
          </div>
        </div>
        <img src="./../../assets/images/rule_img.png" alt="" class="ruleImg">
      </div>

      <div style="width:60rem;height: 5rem;border-bottom:1px solid #0033FF;margin: 0 auto"  id="two"></div>
      
      <div style="width:60rem;height: 3.5rem;"></div>
      
      <div class="aboutMK">
        <div class="aboutMK_text">
          <p class="aboutMK_text_title">{{$t("lang.guanyumk")}}</p>
          <div class="info">
            <p>{{$t("lang.about1")}}</p>
            <p style="margin-bottom:10px">{{$t("lang.about2")}}</p>
            <p style="margin-bottom:10px">{{$t("lang.about3")}}</p>
            <p style="margin-bottom:10px">{{$t("lang.about4")}}</p>
            <p>{{$t("lang.about5")}}</p>
            <p>{{$t("lang.about6")}}</p>
            <p>{{$t("lang.about7")}}</p>
            <p>{{$t("lang.about8")}}</p>
            <p>{{$t("lang.about9")}}</p>
            <p>{{$t("lang.about10")}}</p>
          </div>
        </div>
        <div class="aboutimg">
          <img src="./../../assets/images/abount_img.png" alt="">
          <!-- <img src="" alt="">
          <img src="" alt="">
          <img src="" alt=""> -->
        </div>
      </div>
      
      <div style="height:3.5rem;width:100%" id="three"></div>
      <div class="splitview" ></div>
      <!-- 路线图 -->
      <div class="roadMapview" >
        <p class="roadTitle">{{$t("lang.luxiantu")}}</p>
        <p class="roadTitle1">{{$t("lang.luxiantu1")}}</p>
        <div class="roadContent">
          <div class="left">
            <div class="roadItem leftItem">
              <span></span>
              <p>0% SALES</p>
              <p>{{$t("lang.luxiantu2")}}</p>
            </div>
            <div class="roadItem leftItem">
              <span></span>
              <p>30% SALES</p>
              <p>{{$t("lang.luxiantu4")}}</p>
            </div>
            <div class="roadItem leftItem">
              <span></span>
              <p>50% SALES</p>
              <p>{{$t("lang.luxiantu6")}}</p>
            </div>
            <div class="roadItem leftItem">
              <span></span>
              <p>70% SALES</p>
              <p>{{$t("lang.luxiantu8")}}</p>
            </div>
            <div class="roadItem leftItem">
              <span></span>
              <p>90% SALES</p>
              <p>{{$t("lang.luxiantu10")}}</p>
            </div>
          </div>
          <img class="mid" src="./../../assets/images/road_img.png" alt=""/>
          <div class="midview"></div>
          <div class="right">
             <div class="roadItem rightItem" style="margin-top: 4.4rem">
              <span></span>
              <p>20% SALES</p>
              <p>{{$t("lang.luxiantu3")}}</p>
            </div>
            <div class="roadItem rightItem">
              <span></span>
              <p>40% SALES</p>
              <p>{{$t("lang.luxiantu5")}}</p>
            </div>
            <div class="roadItem rightItem">
              <span></span>
              <p>60% SALES</p>
              <p>{{$t("lang.luxiantu7")}}</p>
            </div>
            <div class="roadItem rightItem">
              <span></span>
              <p>80% SALES</p>
              <p>{{$t("lang.luxiantu9")}}</p>
            </div>
            <div class="roadItem rightItem">
              <span></span>
              <p>100% SALES</p>
              <p>{{$t("lang.luxiantu11")}}</p>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;height: 4rem" id="four"></div>
      <div class="splitview" ></div>
      <!-- 团队 -->
      <div class="teamview" >
        <p class="teamTitle">{{$t("lang.tuandui")}}</p>
        <p class="teamTitle1">{{$t("lang.tuanduijieshao")}}</p>
        <div class="teamContent">
          <div class="teamItem" v-for="(item,index) in teamList" :key="index">
            <img :src="item.img" alt="">
            <p class="name">{{item.name}}</p>
            <div class="info">
              <p>{{$t(item.info1)}}</p>
              <p>{{$t(item.info2)}}</p>
            </div>
          </div>
        </div>
      </div>
      <a class="tips" href="https://etherscan.io/address/0x90cbb1fc51076bf443d5c2e4bfffe33c7d7125f2" target="_blank">0x90cbb1fc51076bf443d5c2e4bfffe33c7d7125f2</a>
    </div>

    <!-- 底部图标 -->
    <div class="bottomIcon_link">
      <a href="https://twitter.com/MKclubHQ?s=09" target="_blank"><img src="./../../assets/images/footer_icon1.png" alt=""></a>
      <a href="https://discord.gg/YBd7VqEA6H" target="_blank"><img src="./../../assets/images/footer_icon3.png" alt=""></a>
      <a><img src="./../../assets/images/phonebottomlogo.png" alt="" @click="openInvite"></a>
    </div>

    <PcModal v-if="showModal" @closeModal="closePCmodal" :title="$t('lang.yaoqinglianjie')">
      <div slot="content"  class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <div class="copyContent">
            <span>https://mkclub.io/#/home?from={{this.dealAddr()}}</span>
            <img src="../../assets/images/copyfill.png" alt="" @click="copyUrl">
          </div>
        </div>
        <div class="buy_succes_modal_btn" @click="copyUrl">{{$t("lang.fuzhi")}}</div>
      </div>
    </PcModal>
  </div>
</template>
<script>

import PcModal from '../../components/PcModal.vue'
// 交易hash
export default {
  name: "detail",
  components: {
    PcModal
  },
  data() {
    return { 
      teamList: [
        { img: require('./../../assets/images/teamimg1.png'), name: 'In the six', info1:  "lang.saicheshou", info2: "lang.saicheshou1" },
        { img: require('./../../assets/images/teamimg2.png'), name: 'Nobysun', info1: "lang.shejishi", info2: "lang.shejishi1" },
        { img: require('./../../assets/images/teamimg3.png'), name: 'Wiehenvenator', info1: "lang.faxingzhe", info2: "lang.faxingzhe1" },
        { img: require('./../../assets/images/teamimg4.png'), name: 'B. X.', info1: "lang.jinrongzhuanjia", info2: "lang.jinrongzhuanjia1" },
        { img: require('./../../assets/images/teamimg5.png'), name: 'Anne', info1: "lang.dukadi", info2: "lang.dukadi1" },
        { img: require('./../../assets/images/teamimg6.png'), name: 'H.B.', info1: "lang.yuanzhuming", info2: "lang.yuanzhuming1" },
      ],
      elementScroll: { one: 0, two: 0, three: 0, four: 0 },
      showModal: false,
    };
  },
   methods: {
    dealAddr() {
      const addr = this.$store.state.userAddress;
      const str = addr.slice(2,addr.length)
      if(!addr) {
        return ''
      } 
      return '0x'+str.slice(0,4)+str.slice(-4)
    },
    pageScroll(e) {
      if(this.$route.name != 'home') {
        return 
      }
      const scrollLen = (e.srcElement?.documentElement?.scrollTop + 50);
      
      console.log(scrollLen,this.elementScroll['four'])
      if(scrollLen==0) {
        // this.$emit('setScrollEle',1)
        this.scrollEle!=1 && this.$emit('setScrollEle',1)
      } else if(scrollLen < this.elementScroll['two']) { // one
        this.scrollEle!=1 && this.$emit('setScrollEle',1)
      } else if(this.elementScroll['two'] < scrollLen && scrollLen < this.elementScroll['three']) { // two

        this.scrollEle!=2 && this.$emit('setScrollEle',2)
      } else if(this.elementScroll['three'] < scrollLen && scrollLen < this.elementScroll['four']) { // three
        this.scrollEle!=3 && this.$emit('setScrollEle',3)
      }  else if(scrollLen > this.elementScroll['four']) { // partners
        this.scrollEle!=4 && this.$emit('setScrollEle',4)
      }
    },
    goMint() {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      this.$router.push('mint')
    },
    openInvite() {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      this.showModal = true;
    },
    closePCmodal() {
      this.showModal = false;
    },
    copyUrl() {
      const Url2 = `https://mkclub.io/#/home?from=${this.dealAddr()}`
      var input = document.createElement('input'); // 创建input标签，只有input标签选中可以
      input.setAttribute('id', 'copyInput'); // 给input一个id属性
      input.setAttribute('value', Url2); // 给input一个value属性，属性值是变量span的文本
      document.getElementsByTagName('body')[0].appendChild(input); // body里面添加input实体
      document.getElementById('copyInput').select(); // 使用js去通过id找到并执行input实体的全部选中
      document.execCommand("Copy"); //原生copy方法执行浏览器复制命令
      document.getElementById('copyInput').remove(); // 为避免下次页面添加copy方法 所以这里copy完之后要移除input实体
      this.$message({
      type: 'success',
      message: this.$t("lang.fuzhichenggong")
      })
    },
  },
  mounted() {
    setTimeout(()=>{
        for( let item in this.elementScroll) { 
          this.elementScroll[item] = Number(document.getElementById(item)?.offsetTop);
        }
        this.$nextTick(()=>{
          this.$emit('scrollPage',sessionStorage.getItem('tabval'));
          sessionStorage.removeItem('tabval');
        },)
      },500)
    window.addEventListener('scroll',this.pageScroll)
  },
};
</script>

<style lang="less">
#detailsPage {
  .banner {
    width: 100%;
    height: 34rem;
    background: url('./../../assets/images/indexBg.png') no-repeat;
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    position: relative;
    .bannerImg {
      width: 60rem;
      height: 100%;
    }
    .bgicon {
      position: absolute;
      left: 0;
      top: 13.6rem;
      width: 30.95rem;
      z-index: 1;
    }
  }
  .mint_info {
    width: 100%;
    padding-top: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .mint_bannerView {
      width: 60rem;
      padding-top: 4.05rem;
      display: flex;
      align-items: center;
      border-top: 1px solid #0033FF;
      .mint_banner {
        width: 100%;
        height: 8.45rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        background: url('./../../assets/images/mintbanner.png') no-repeat;
        background-size: 100% 100%;
        padding: 0 2.45rem;
        .left {
          height: 5.05rem;
          margin-top: 3.85rem;
          p{
            &:first-child {
              color: #fff;
              font-size: 0.75rem;
              margin-bottom: 0.4rem;
            }
            &:last-child {
              padding: 0.2rem 0.5rem;
              font-size: 1.5rem;
              color: #fff;
              font-weight: bold;
              background-color: #0033FF;
            }
          }
        }
        .right {
          display: flex;
          align-items: flex-end;
          margin-bottom: 0.5rem;
          p {
            padding: 0.15rem;
            font-size: 0.75rem;
            color: #fff;
            font-weight: 600;
            padding-right: 1.1rem;
            background: url('./../../assets/images/arrow-right-filling.png') no-repeat right 0.25rem center / 0.6rem auto;
            background-color: #0033FF;
          }
          img {
            width: 5.05rem;
            height: 5.05rem;
            margin-left: 3.35rem;
            cursor: pointer;
          }
        }
      }
    }

    .aboutMK {
      width: 60rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .aboutMK_text {
      }
      .aboutMK_text_title {
        font-size: 2rem;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 2rem;
        
      }
      .info {
        text-align: justify;
        font-size: 0.75rem;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 1rem;
      }
      .aboutimg {
        width: 24rem;
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        margin-left: 4rem;
        img {
          width: 100%;
          // height: 10rem;
          // margin-bottom: 1.5rem;
          // &:nth-child(odd) {
          //   margin-right: 1.5rem;
          // }
        }
      }
    }
    .ruleview {
      width: 60rem;
      margin-bottom: 5.4rem;
      display: flex;
      position: relative;
      border-top: 1px solid #0033FF;
      .ruleImg {
        width: auto;
        height: 37rem;
        position: absolute;
        right: 0;
        top: 0;
      }
      .ruleContent {
        width: 26rem;
        .ruleTitle {
          font-size: 2rem;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          margin-top: 5.95rem;
          margin-bottom: 2.4rem;
        }
        p {
          font-size: 0.75rem;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 1rem;
        }
        .gomintBtn {
          margin-top: 2.2rem;
          width: 15rem;
          height: 3.05rem;
          background: linear-gradient(to left, #0061FF,#0033FF);
          cursor: pointer;
          padding-left: 1.1rem;
          line-height: 3.05rem;
          text-align: left;
          font-size: 0.75rem;
          font-weight: bold;
          color: #FFFFFF;
          position: relative;
          &:hover {
            background: linear-gradient(to left, #88ABFF,#5072FF);
          }
          img {
            position: absolute;
            right: 2.25rem;
            top: -0.6rem;
            width: 4.45rem;
            height: 4.45rem;
          }
        }
      }
    }
    .splitview {
      width: 60rem;
      height: 6rem;
      margin: 0 auto;
      border-top: 1px solid #0033FF;
    }
    .roadMapview {
      width: 60rem;
      margin: 0 auto;
      .roadTitle {
        font-size: 2rem;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 1.6rem;
      }
      .roadTitle1 {
        font-size: 0.75rem;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 4.4rem;
      }
      .roadContent {
        display: flex;
        position: relative;
        padding-top: 13rem;
        .mid {
          width: 15rem;
          height: auto;
          position: absolute;
          left: 50%;
          top: 0;
          margin-left: -7.5rem;
        }
        .midview {
          width: 5px;
          height: 100%;
        }
        .left {
          flex: 1;
          text-align: right;
        }
        .right {
          flex: 1;
          text-align: left;
        }
        .leftItem {
          padding-right: 4rem;
          span {
            position: absolute;
            right: 0;
            top: 0.75rem;
            width: 2rem;
            height: 4px;
            background: #0033FF;
          }
        }
        .rightItem {
          padding-left: 4rem;
          span {
            position: absolute;
            left: -1px;
            top: 0.75rem;
            width: 2rem;
            height: 4px;
            background: #0033FF;
          }
        }
        .roadItem {
          margin-bottom: 8.4rem;
          position: relative;
          p {
            color: #fff;
            &:first-child {
              font-size: 1.5rem;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold;
              margin-bottom: 0.9rem;
            }
            &:last-child {
              font-size: 1rem;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold
            }
          }
        }
      }
    }

    .teamview {
      width: 60rem;
      margin: 0 auto;
      .teamTitle {
        font-size: 2rem;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 1.6rem;
      }
      .teamTitle1 {
        font-size: 0.75rem;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 6rem;
      }
      .teamContent {
        margin-bottom: 13.5rem;
        .teamItem {
          display: flex;
          align-items: center;
          margin-bottom: 5.7rem;
          &:last-child {
            margin-bottom: 0;
          }
          img {
            width: 7.15rem;
            height: 5rem;
            margin-right: 2.5rem;
            flex-shrink: 0;
          }
          .name {
            width: 7.5rem;
            ont-size: 1rem;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
            margin-right: 2.5rem;
          }
          .info {
            flex: 1;
            font-size: 0.75rem;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1.5rem;
            text-align: left;
          }
        }
      }
    }
    .tips {
      text-align: center;
      font-size: 0.75rem;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #0033FF;
      text-decoration: none;
      padding-bottom: 6rem;
    }
  }
  .bottomIcon_link {
    position: fixed;
    width: 100%;
    bottom: 7.35rem;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5.4rem;
    z-index: 10;
    a {
      width: 1rem;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
    img {
      width: 1rem;
      cursor: pointer;
      
    }
  }
}
.copyContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .5rem;
  box-sizing: border-box;
  span {
    font-size: 0.8rem;
    color: #000;
    font-weight: bold;
    text-align: left;
    padding: 0;
    word-break: break-all;
  }
  img {
    width: 1.2rem;
    height: auto;
    cursor: pointer;
    margin-left: 1.2rem;
  }
}
</style>
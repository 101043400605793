// 日文  key由 文字中的四个字的拼音组成
export const lang = {

  'guanbi': '閉じる',
  "zhuzaochenggong":"鋳造成功",
  'yitijiao': '取引提出済み',


  'lianjieqianbao':'ウォレット接続',
  'gongxi':'おめでとう',
  'wodeNFT':'NFT',
  'querenzheci':'ウォレットで取引と確認',
  'wodeqianbao':'ウォレット',
  'duankailianjie':'接続オフ',
  'lianjieshiba':'接続失敗',
  'lianjiebeijujie':'接続失敗、拒絶された',
  'wanluocuowu':'网ネットエラー络错误',
  'qingqiehuanwang':'请切换到主网=ネットエラー、メインネットへ切り替え',
  'qiehuanwangluo':'メインネットへ切り替え。',
  'jiaoyishibai':'取引失敗',
  'jiaoyijujie':'取引要請が拒絶された',
  
  "dianjijinru":"入る",
  "lianjiematemask":"MetaMaskウォレット接続",
  "lianjiewallect":"WalletConnect接続",
  "zhuzao":"鋳造",
  "luxiantu":"路線図",
  "tuandui":"チーム",
  "meirixiangliang":"毎日限定供給し、割引で早期購入しよう",
  "mkcdengdai":"9000 MKC鋳造待ち",
  "dianjizhuzao":"鋳造",
  "guanyumk":"MKに関して",
  
  "about1":"MKが天界から仏教の経典を持ち帰ってから一万年が経ちました。",
  "about2":"月日が過ぎて世の中は変り、地球の主宰者である人類は人間性の解放という道を歩み続けてきました。人間性が神性に代わり、物欲が禁欲に代わり、俗世の楽しみが聖なる信仰に代わってしまいました。人類の貪欲は地球万物の生きようとする意志を途絶えさせました。人類は互いを憎み、競争し合い、戦争を起こし、種族間が差別し、ウイルスが蔓延しています……人類の文明は崩壊寸前です。",
  "about3": "人類を救うために、MKは一万の分身を作り、人々に混じり込みました。一万人の分身が宿っている選ばれし者たちは毎日善と悪の試練を受け、最後はMKになれます。",
  "about4": "善か悪かはほんの一念です。MKの分身が俗世に降臨したことによってもたらされた「一念禅語」は選ばれし者たちが人間性の試練を乗り越えるのに役立ちます。",
  "about5": "MKの選ばれし者たちは五つの種族に分け、それぞれ人間として弱点と優位性を持っています：",
  "about6": "ソフトキュート族-大きくなりたくないという子供心を持っており、複雑で残酷な大人の社会が嫌いで、単純で優しいという人間の本性に戻ろうとしている。",
  "about7": "ストリート族-社会の不公平と禁錮に反抗して叫び、どん底にいてもパラダイスに手を届けようとする勇気を持っています。",
  "about8": "マジック族-繊細で感情が豊富で、想像力があって、鋭い第六感と予知能力を持っている者が多いです。",
  "about9": "オートバイ族-自由気ままで、冒険が溢れている旅をして自分自身の極限と勇気に挑戦します。",
  "about10": "宇宙族-未解決の物事に対して知識欲があり、未来を探索するために生まれる者たちです。",

  "zhuzaoguize":"鋳造ルール",
  "fenpeijizhi":"分配",
  "baoliu":"留保",
  "1000ge":"1000個",
  "9000ge":"9000個",
  "diyicishifang":"一回目は2000個をリリースし、72日後、毎週の火曜日午前3時に700個をリリースし、10週間続く",
  "zhuzaojiage":"鋳造価格",
  "MINTshuliang":" MINT 合計数",
  "1_72tian":"1-72日",
  "MINTjiage":"MINT 価格",
  "73_108tian":"73-108日",
  "109tian":"109日後",
  "zhanghurengoucishu":"一つのアカウントは一回だけ、1－3個を購入する（最小1個、最多3個）",
  "luxiantu1":"路線図に基づいてMK Labsメタバースを徐々に作り上げる。",
  "luxiantu2":"自分のtwitter、discord公式メディア及びコミュニティを持つ",
  "luxiantu3":"MK Orientalグッズを打ち出す",
  "luxiantu4":"自分のYouTubeチャンネル及びTiktok公式メディアを持つ",
  "luxiantu5":"オフラインでは、流行物の巡回展覧を行い、限定のT－シャツ、スニーカー、そのほかを打ち出す",
  "luxiantu6":"MK Boutique--独占商品/限定版収蔵品を打ち出す",
  "luxiantu7":"オフラインでは、デジタル芸術品の競売をし、有名な都市の ランドマークのところにMK彫像を置く",
  "luxiantu8":"有名なプロデューサーと協力してMK videoをリリースし、オフラインでは会員サロンを組織する",
  "luxiantu9":"openseaリピ買いし、MKを持っている有名ブランドと協力して一連の共同活動を行う",
  "luxiantu10":"専属のバーチャル土地を購入してメタバースを展示し、GameFiの開発を開始する",
  "luxiantu11":"Gala&Meetups祝典とオフライン会員サロン、MKC会員にNFTの路線図を三つエアドロップすることを公開する",
  "tuanduijieshao":"開発及び運営チームの紹介",
  "saicheshou":"レーシングドライバー",
  "saicheshou1":"最高の旅行と探検の愛好者！ずっと旅している修行者",
  "shejishi":"想像力が豊かなクレイジーデザイナー",
  "shejishi1":"普段はダラダラしているので、不自由に暮らしている。もう一度最初からやり直せるならと何度も願った結果、神様からチャンスをいただいた。いかに過ごし、何をすれば、人生を悔いなしに生きられるのだろうか？！",
  "faxingzhe":"Mobile gameの最初の発行者",
  "faxingzhe1":"フランスのワイン、キューバのシガー、スコッチハイランドウイスキーの大ファンである",
  "jinrongzhuanjia":"ブロックチェーン技術と金融の専門者",
  "jinrongzhuanjia1":"ペット愛好者、特にキングコブラが好き、いつも黄色い犬と一緒にいる",
  "dukadi":"トゥカティバイク愛好者",
  "dukadi1":"プログラマーの経験を活かしてラスベガスにあるカジノの優秀なディーラーになった",
  "yuanzhuming":"ブロックチェーン業界の原住民",
  "yuanzhuming1":"m65を着て、ak47を提げていて、アフリカのサバンナでは白と黒の世界を自由に行き来する。",
  "mincheng":"名前",
  "shifang":"リリース",
  "jiage":"価格",
  "yixuan":" 選択済み",
  "zongji":"合計",
  "yue":"残高",
  "zhuzaozhong":"鋳造中",
  "yuebuzu":"残高不足",
  "dengdaiqueren":"確認待ち",
  "zhuzaowancheng":"鋳造完成",

  "yaoqinglianjie": '招待リンク',
  "fuzhi": "コピー",
  "fuzhichenggong": "コピー成功",
  "dakaiqianbao": "分散型ウォレットを開く",
  "lianjiefuzhi": "サイトのリンクをコピーし、ウォレットブラウザで開いて鋳造する"
}
// 韩文 key由 文字中的四个字的拼音组成
export const lang = {

  'guanbi': '닫기',
  "zhuzaochenggong":"민트 성공",
  'yitijiao': '제출된 거래',


  'lianjieqianbao':'지갑연결',
  'tuandui':'팀',
  'luxiantu':'로드맵',
  'yue':'잔액',
  'gongxi':'축하',
  'wodeNFT':'나의NFT',
  'yuebuzu':'잔액부족',
  'querenzheci':'지갑에서의 거래임을 확인',
  'wodeqianbao':'나의지갑',
  'duankailianjie':'연결차단',
  'lianjieshiba':'연결실패',
  'lianjiebeijujie':'연결실패, 연결이 거부됨',
  'wanluocuowu':'네트워크오류',
  'qingqiehuanwang':'네트워크오류, 메인넷에 연결。',
  'qiehuanwangluo':'메인넷으로 전환',
  'pizhunshibai':'승인실패',
  'pizhunjujie':'승인청구가 거부됨',
  'jiaoyishibai':'거래실패',
  'jiaoyijujie':'거래청구가 거부됨',
  'lianjieMetamask':'MetaMask지갑에 연결',
  'lianjieWalletConnect':'WalletConnect에 연결',
  // 
  "linajieqianbao":"지갑연결",
  "dianjijinru":"들어가기",
  "lianjiematemask":"MetaMask지갑에 연결",
  "lianjiewallect":"WalletConnect에 연결",
  "luxiantu":"로드맵",
  "tuandui":"팀",
  "meirixiangliang":"매일 한정 제공  조기 구매로 혜택 누리기",
  "mkcdengdai":"9000 MKC민트 대기",
  "dianjizhuzao":"민트하기",
  "guanyumk":"MK정보",
  
  "about1":"MK가 서천에서 진경을 얻고 돌아온 지 이미 만년이 되었다",
  "about2":"세월이 지나고 세상이 바뀌면서 지구의 지배자인 인간은 인간성 해방의 길로 점점 더 멀리 나아가서 인간성이 신성을, 물욕이 금욕을, 세속적인 쾌락이 신성한 신앙을 대체했다. 인간의 탐욕스러운 본성은 지구상의 모든 것을 절망에 빠뜨렸다. 인간 사이의 상호 증오, 불공정, 전쟁, 인종차별, 바이러스 만연... 인류 문명은 멸망 직전에 이르렀다.",
  "about3": "인류를 구하기 위해 MK는 1만 개의 분신으로 변신해 사람들 속에 들어간다. .이 만 명의 분신이 입정한 하늘이 선택한 자들은 반드시 매일 인간성 선과 악의 시련을 겪어야 나중에 MK로 수련될 수 있다. 일 만명 MK가 집결하여 함께 지구를 보호한다.",
  "about4": "선과 악은 일념지간의 차이로 MK 분신의 강림은 세상에 “일념선어”를 가져와 하늘이 선택한 자들을 도와 복잡한 인간성의 시련을 이겨내도록 한다.",
  "about5": "MK 하늘이 선택한 자들은 5개 종족으로 나누며, 각자 모두 인간성의 단점과 장점이 있다：",
  "about6": "귀염족-어른이 되기 싫어하는 동심을 가지고 있으며, 복잡하고 가혹한 성인사회를 혐오하여 순수하고 선량한 인간성의 본질로 돌아갈 것을 바란다.",
  "about7": "스트리트족-사회의 불공정과 속박에 반항하고 저항하고 절규한다. 사회 하층에 있어도 천국을 만지려는 용기가 있다.",
  "about8": "매직족-마음이 섬세하고, 감정이 풍부하며, 환상이 차넘치는 매직족의 많은 사람들이 예리한 제6감과 미래를 예측하는 능력을 가지고 있다.",
  "about9": "오토바이족-평생 구속을 받지 않고 자유를 원하며, 모험의 여정으로 자신의 한계와 마음 속의 용기에 도전한다.",
  "about10": "인터스텔라족-모든 미해결 사물에 대해 강렬한 구지욕이 있고, 이 세상을 살아가는 원인이 미래를 탐구하는 것이라고 여긴다.",

  "zhuzaoguize":"민트 규칙",
  "fenpeijizhi":"배분 메커니즘",
  "baoliu":"보류",
  "1000ge":"1000개",
  "zhuzao":"민트",
  "9000ge":"9000개",
  "diyicishifang":"최초 2000개 릴리즈, 72일 후 매주 화요일 새벽 3시 700개 릴리즈, 10주 지속 릴리즈.",
  "zhuzaojiage":"민트 가격",
  "MINTshuliange":"MINT 수량",
  "1_72tian":"1-72일",
  "MINTjiage":"MINT 가격",
  "73_108tian":"73-108일",
  "109tian":"109일 후",
  "zhanghurengoucishu":"계정당 1회 구매, 1회 1-3개(최소 1개, 최고 3개)",
  "luxiantu":"로드맵",
  "luxiantu1":"로드맵에 따른 MKLab 메타버스 세계 구축 계획",
  "luxiantu2":"Twitter, Discoord 공식 미디어 매트릭스 및 커뮤니티를 소유",
  "luxiantu3":"MK Oriental아트 토이파생품출시",
  "luxiantu4":"YouTube 채널 및 TikTok 공식 미디어 소유",
  "luxiantu5":"오프라인 아트 토이 공동순회 전시및 한정판 티셔츠, 운동화 및 기타 굿즈 출시",
  "luxiantu6":"MK Boutique -- 독점상품/한정판 컬렉션 출시",
  "luxiantu7":"오프라인 디지털 아트 경매, 유명 도시의 랜드마크 건물에 MK 조각상 배치",
  "luxiantu8":"유명 프로듀서와 공동으로 MK video 발표및 오프라인 멤버 살롱 조직",
  "luxiantu9":"opensea바이백, MK를 보유한 유명 브랜드와 공동으로 CO 브랜딩 활동 실시",
  "luxiantu10":"메타버스 공간을 전시하기 위한 전속 가상토지 구매 및 GameFi개발",
  "luxiantu11":"Gala & Meetups 축하 & 오프라인회원살롱, MKC 회원을대상으로 3개 NFT를공매하는로드맵발표",
  "tuanduijieshao":"개발 및 운영팀 소개",
  "saicheshou":"레이싱 드라이버",
  "saicheshou1":"궁극의 여행과 모험 애호가! 항상 길 위에서의스타일리스트",
  "shejishi":"강력하고 자유로운 스타일을 가진 열광의디자이너",
  "shejishi1":"뚜렷한 목표가 없는삶은 여의치가 않다. 천만번 다시 하기를 바랬고, 마침내 하나님께서 이 기회를 주셨다. 어떻게 잡을지, 어떻게 해야할지, 어떻게 해야 후회없는 삶을 만들 수 있을까?！",
  "faxingzhe":"Mobile game의 최초 발행자들",
  "faxingzhe1":"프랑스 와인과 쿠바 시가 애호자이며 스코틀랜드 하이랜드 위스키의 열렬한 팬이기도 하다.",
  "jinrongzhuanjia":"블록체인 기술과 금융 전문가",
  "jinrongzhuanjia1":"애완동물 애호가, 킹 코브라의 돌아보는 모습을 즐기며 매일 누렁개가 함께 한다..",
  "dukadi":"두카티 몬스터애호가",
  "dukadi1":"프로그래머의 경험을 빌어, 라스베가스 카지노의 훌륭한 딜러가 되었다.",
  "yuanzhuming":"블록체인 업계 원주민",
  "yuanzhuming1":"m65를 입고 ak47을 들고,아프리카 대초원에서 흑과 백 사이를자유롭게 넘나든다.",
  "mincheng":"이름",
  "shifang":"릴리즈",
  "zhuzao":"민트",
  "jiage":"가격",
  "yixuan":" 선택완료",
  "zongji":"총계",
  "yue":"금액",
  "zhjuzaozhong":"마이닝",
  "yuebuzu":"잔액 부족",
  "dengdaiqueren":"확인대기",
  "zhuzaowancheng":"민트 완료",

  "yaoqinglianjie": '요청 링크',
  "fuzhi": "클립보드에 복사",
  "fuzhichenggong": "복사 성공",
  "dakaiqianbao": "탈중심화된 지갑을 열어라",
  "lianjiefuzhi": "웹 사이트 링크를 복사한 후 지갑 브라우저에서 열어 주조하세요"
}
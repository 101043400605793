<template>
  <div>
    <router-view/>  
  </div>
</template>

<script>
import API from "@/assets/js/API";
export default {
  name: "App",
  data() {
    return {
      
    }
  },
  
  mounted() {
    const url = window.location.origin;
    const href = window.location.href;
    const param = href.split('?')[1] || '';
    const hash = window.location.hash;
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      
      if(!hash.includes('h5')) {
        window.location.href = `${url}/#/h5home${param ? '?'+param :''}`
      }
      
    } else {
      if(hash.includes('h5')) {
        window.location.href = `${url}/#/home${param ? '?'+param :''}`
      } else {
        
      }
     
    }

  },
  methods: {
  
  }
};
</script>

<style>

</style>

<template>
  <div id="detailsphonePage">
    <img src="./../../assets/images/banner1_phone.png" alt="" class="banner">
    <div class="mintbtnview">
      <div class="minBtn">
        <p class="title1">{{$t("lang.meirixiangliang")}}</p>
        <p class="title2">{{$t("lang.mkcdengdai")}}</p>
        <div class="btnview">
          <p>
            <span>{{$t("lang.dianjizhuzao")}}</span>
            <img src="./../../assets/images/arrow-right-filling.png" alt="">
          </p>
          <img src="./../../assets/images/btnlx.gif" alt="" class="btnright" @click="goMint">
        </div>
      </div>
    </div>
    <div style="height:22px;width:100%;"></div>
    
    <div style="height:25px;width:100%;border-bottom: 1px solid #0033FF;" id="one"></div>
    <!-- 规则 -->
    <div class="ruleview">
      <p class="title">{{$t("lang.zhuzaoguize")}}</p>
      
      <p class="txt1">{{$t("lang.fenpeijizhi")}}</p>
      <p class="txt1">{{$t("lang.baoliu")}}:{{$t("lang.1000ge")}}（#0-#999）</p>
      <p class="txt1 marginbotom">{{$t("lang.zhuzao")}}：{{$t("lang.9000ge")}}</p>

      <p class="txt1">{{$t("lang.zhuzaoguize")}}</p>
      <p class="txt1 marginbotom">{{$t("lang.diyicishifang")}}</p>

      <p class="txt1">{{$t("lang.zhuzaojiage")}}</p>
      <p class="txt1">{{$t("lang.1_72tian")}}</p>
      <p class="txt1">{{$t("lang.MINTjiage")}}：2.0ETH</p>
      <p class="txt1">{{$t("lang.73_108tian")}}</p>
      <p class="txt1">{{$t("lang.MINTjiage")}}：2.5ETH</p>
      <p class="txt1">{{$t("lang.109tian")}}</p>
      <p class="txt1 marginbotom">{{$t("lang.MINTjiage")}}：3.0ETH</p>

      <p class="txt1">{{$t("lang.MINTshuliang")}}</p>
      <p class="txt1 marginbotom">{{$t("lang.zhanghurengoucishu")}}</p>


      <!-- <p class="txt1">Token Type：ERC-721A</p>
      <p class="txt1">Blockchain：Ethereum</p>
      <p class="txt1 marginbotom">File Hosting：IPFS</p> -->

      <div class="gomintBtn" @click="goMint">
        {{$t("lang.dianjizhuzao")}}
        <img src="./../../assets/images/btnlx.gif" alt="" class="icon">
      </div>

      <div class="ruleImg">
        <img src="./../../assets/images/rule_img_phone.png" alt="">
      </div>
      
    </div>
    <div style="height:25px;width:100%;" id="two"></div>
    <!-- MK -->
    <div class="aboutMK">
      <p class="title">{{$t("lang.guanyumk")}}</p>

      <p class="txt1">{{$t("lang.about1")}}</p>
      <p class="txt1" style="margin-bottom:15px">{{$t("lang.about2")}}</p>
      <p class="txt1" style="margin-bottom:15px">{{$t("lang.about3")}}</p>
      <p class="txt1" style="margin-bottom:15px">{{$t("lang.about4")}}</p>
      <p class="txt1">{{$t("lang.about5")}}</p>
      <p class="txt1">{{$t("lang.about6")}}</p>
      <p class="txt1">{{$t("lang.about7")}}</p>
      <p class="txt1">{{$t("lang.about8")}}</p>
      <p class="txt1">{{$t("lang.about9")}}</p>
      <p class="txt1">{{$t("lang.about10")}}</p>


      <div class="aboutImg">
        <img src="./../../assets/images/abount_img_phone.png" alt="">
      </div>
    </div>
    <div style="height:90px;width:100%;"></div>
    <!-- 路线图 -->
    <div class="roadview">
      <div style="height:72px;width:100%;" id="three"></div>
      <p class="title">{{$t("lang.luxiantu")}}</p>
      <p class="title1">{{$t("lang.luxiantu1")}}</p>
      <div class="roaditem" v-for="(item,index) in list" :key="index">
        <p>{{item.value}}</p>
        <p>{{$t(item.title)}}</p>
      </div>
    </div>
    <div style="height:48px;width:100%;" ></div>
    <!-- team -->
    <div class="teamview">

      <div style="height:72px;width:100%;" id="four"></div>

      <p class="title">{{$t("lang.tuandui")}}</p>
      <p class="title1">{{$t("lang.tuanduijieshao")}}</p>
      <div class="teamitem" v-for="(item,index) in teamList" :key="index">
        <img :src="item.img" alt="">
        <p class="name">{{item.name}}</p>
        <p class="info1">{{$t(item.info1)}}</p>
        <p class="info2">{{$t(item.info2)}}</p>
      </div>
      <a class="tips" href="https://etherscan.io/address/0x90cbb1fc51076bf443d5c2e4bfffe33c7d7125f2" target="_blank">0x90cBB1fc51076bF443d5c2e4BFFfE33c7D7125F2</a>
    </div>

    <div class="logoview">
      <img src="./../../assets/images/logoicon.png" alt="">
    </div>
  </div>
</template>
<script>

// 交易hash
export default {
  name: "detail",
  components: {
  },
  data() {
    return {
      list: [
        {title: "lang.luxiantu2", value: '0%'},
        {title: "lang.luxiantu3", value: '20%'},
        {title: "lang.luxiantu4", value: '30%'},
        {title: "lang.luxiantu5", value: '40%'},
        {title: "lang.luxiantu6", value: '50%'},
        {title: "lang.luxiantu7", value: '60%'},
        {title: "lang.luxiantu8", value: '70%'},
        {title: "lang.luxiantu9", value: '80%'},
        {title: "lang.luxiantu10", value: '90%'},
        {title: "lang.luxiantu11", value: '100%'},
      ],
      teamList: [
        { img: require('./../../assets/images/teamimg1.png'), name: 'In the six', info1:  "lang.saicheshou", info2: "lang.saicheshou1" },
        { img: require('./../../assets/images/teamimg2.png'), name: 'Nobysun', info1: "lang.shejishi", info2: "lang.shejishi1" },
        { img: require('./../../assets/images/teamimg3.png'), name: 'Wiehenvenator', info1: "lang.faxingzhe", info2: "lang.faxingzhe1" },
        { img: require('./../../assets/images/teamimg4.png'), name: 'B. X.', info1: "lang.jinrongzhuanjia", info2: "lang.jinrongzhuanjia1" },
        { img: require('./../../assets/images/teamimg5.png'), name: 'Anne', info1: "lang.dukadi", info2: "lang.dukadi1" },
        { img: require('./../../assets/images/teamimg6.png'), name: 'H.B.', info1: "lang.yuanzhuming", info2: "lang.yuanzhuming1" },
      ],

      elementScroll: { one: 0, two: 0, three: 0, four: 0 }
    };
  },
  computed: {
    
  },
  methods: {
    pageScroll(e) {
      if(this.$route.name != 'h5home') {
        return 
      }
      const scrollLen = (e.srcElement?.documentElement?.scrollTop + 50);
      
      // console.log(scrollLen,this.elementScroll['four'])
      if(scrollLen==0) {
        // this.$emit('setScrollEle',1)
        this.scrollEle!=1 && this.$emit('setScrollEle',1)
      } else if(scrollLen < this.elementScroll['two']) { // one
        this.scrollEle!=1 && this.$emit('setScrollEle',1)
      } else if(this.elementScroll['two'] < scrollLen && scrollLen < this.elementScroll['three']) { // two

        this.scrollEle!=2 && this.$emit('setScrollEle',2)
      } else if(this.elementScroll['three'] < scrollLen && scrollLen < this.elementScroll['four']) { // three
        this.scrollEle!=3 && this.$emit('setScrollEle',3)
      }  else if(scrollLen > this.elementScroll['four']) { // partners
        this.scrollEle!=4 && this.$emit('setScrollEle',4)
      }
    },
    goMint() {
      this.$router.push('h5mint')
    }
  },
  mounted() {
    setTimeout(()=>{
        for( let item in this.elementScroll) { 
          this.elementScroll[item] = Number(document.getElementById(item)?.offsetTop);
        }
        this.$nextTick(()=>{
          this.$emit('scrollPage',sessionStorage.getItem('tabvalh5'));
          sessionStorage.removeItem('tabvalh5');
        },)
      },500)
    window.addEventListener('scroll',this.pageScroll)
  }
};
</script>

<style lang="less">
#detailsphonePage {
  padding-top: 20px;
  .banner {
    width: 100%;
    height: auto;
    margin-bottom: 48px;
  }
  .mintbtnview {
    width: 100%;
    padding: 47px 29px;
    border-top: 1px solid #0033FF;
    padding-bottom: 0;
    
    .minBtn {
      width: 100%;
      min-height: 222px;
      background: url('./../../assets/images/mintbanner_phone.png.png') no-repeat;
      background-size: 100% 100%;
      padding-top: 54px;
      padding-left: 17px;
      padding-right: 19px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title1 {
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 12px;
      }
      .title2 {
        padding: 6px 10px;
        background: #0033FF;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
      }

      .btnview {
        width: 100%;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 13px;
        p {
          width: 78px;
          height: 21px;
          background: #0033FF;
          font-size: 14px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 8px;
            height: 11px;
            margin-left: 5px;
          }
        }
        .btnright {
          width: 96px;
        }
      }
    }
  }

  .aboutMK {
    width: 100%;
    // border-top: 1px solid #0033FF;
    padding-top: 47px;
    .title {
      font-size: 29px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 48px;
      padding: 0 29px;
    }
    .txt1 {
      font-size: 14px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 19px;
      text-align: justify;
      padding: 0 29px;
    }
    .aboutImg {
      padding: 0 6px;
      img {
        width: 100%;

      }
    }
  }

  .ruleview {
    width: 100%;
    border-top: 1px solid #0033FF;
    padding: 47px 29px 7px 29px;
    .title {
      font-size: 29px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 48px;
    }
    .txt1{
      font-size: 14px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 19px;
      text-align: left;
    }
    .marginbotom {
      margin-bottom: 25px;
    }
    .marginbotom1 {
      margin-bottom: 50px;
    }
    .ruleImg {
      width: 100%;
      padding-left: 5px;
      img {
        width: 100%;
      }
    }
    .gomintBtn {
      width: 288px;
      height: 59px;
      background: linear-gradient(to left, #0061FF,#0033FF);
      margin: 0 auto;
      margin-bottom: 57px;
      line-height: 59px;
      padding-left: 20px;
      font-size: 14px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      text-align: left;
      position: relative;
      img {
        position: absolute;
        right: 43px;
        top: -12px;
        width: 86px;
        height: 86px;
      }
    }
  }

  .roadview {
    width: 100%;
    border-top: 1px solid #0033FF;
    padding: 39px 29px 0 29px;
    .title {
      font-size: 29px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 19px;
    }
    .title1 {
      font-size: 14px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 58px;
    }
    .roaditem {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        &:first-child {
          width: 53px;
          font-size: 19px;
          font-weight: bold;
          color: #5E7EFF;
          margin-right: 18px;
          text-align: left;
        }
        &:last-child {
          flex: 1;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 19px;
        }
      }
    }
  }

  .teamview {
    width: 100%;
    border-top: 1px solid #0033FF;
    padding: 62px 29px 27px 29px;
    .title {
      font-size: 29px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 19px;
    }
    .title1 {
      font-size: 14px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 77px;
    }
    .teamitem {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 48px;
      img {
        width: auto;
        height: 97px;
        margin-bottom: 18px;
      }
      .name {
        font-size: 14px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #5E7EFF;
        margin-bottom: 34px;
        text-align: center;
      }
      .info1,.info2 {
        font-size: 14px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 29px;
        text-align: center;
      }
    }
    .tips {
      display: block;
      margin: 0 auto;
      font-size: 11px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #0033FF;
      line-height: 17px;
      // margin-top: 100px;
      word-break: break-all;
      text-align: center;
      text-decoration: none;
    }

  }

  .logoview {
    padding: 47px 0;
    width: 100%;
    border-top: 1px solid #0033FF;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 130px;
    }
  }
}
</style>
  
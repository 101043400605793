<template>
  <div id="castnft_page">

    <div class="mintview">
      <div class="leftInfo">
        <div class="leftItem">
          <p>{{$t("lang.mincheng")}}</p>
          <p>MKCLUB</p>
        </div>
        <div class="leftItem">
          <p>{{$t("lang.shifang")}}</p>
          <p>{{$store.state.NFTInfo.release}}/{{$store.state.NFTInfo.maxSupply}}</p>
        </div>
        <div class="leftItem">
          <p>{{$t("lang.zhuzao")}}</p>
          <p>{{$store.state.NFTInfo.alreadyMint}}/{{$store.state.NFTInfo.release}}</p>
        </div>
        <div class="leftItem">
          <p>{{$t("lang.jiage")}}</p>
          <p>{{($store.state.NFTPrice * nftNum).toFixed(1)}}ETH</p>
        </div>
      </div>
      <div class="bottom">
        <p class="chooseview">
        {{$t("lang.yixuan")}}{{nftNum}}/3, {{$t("lang.zongji")}}: <span>{{($store.state.NFTPrice * nftNum).toFixed(1)}}ETH</span>
        </p>
        <p class="balance">{{$t("lang.yue")}}: {{$store.state.ETHBalance}}ETH</p>
      </div>

      <div class="input_loading_view">
        <!-- <div class="loadingView" >
          <img src="" alt="" v-if="buyingStatus==2">
        </div> -->
        <div class="inputview">
          <img src="./../../assets/images/miuns.png" alt="" @click="minus">
          <input type="number" readonly v-model="nftNum">
          <img src="./../../assets/images/add.png" alt="" @click="add">
        </div>
      </div>
      

      <div class="buybtnview">
        <!-- 余额不足 -->
        <div class="btn balanceNotMint" v-if="($store.state.NFTPrice * nftNum) > ($store.state.ETHBalance)">
          {{$t("lang.yuebuzu")}}
          <img src="./../../assets/images/lingxing_gray.png" alt="">
        </div>
        <!-- 能铸造 -->
        <div v-else>
          <div class="btn canMint" v-if="buyingStatus!=2" @click="buyNft" :style="disbaleBuy && {  cursor: 'not-allowed' }">
            {{$t("lang.zhuzao")}}
            <img src="./../../assets/images/btnlx.gif" alt="">
          </div>
          <!-- 铸造中 -->
          <div class="btn minting" v-if="buyingStatus==2">
            {{$t("lang.zhuzaozhong")}}
            <div class="minting_loading">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
            <img src="./../../assets/images/btnlx.gif" alt="">
          </div>
        </div>
        
        
      </div>
    </div>

    <!-- 弹窗 -->
    <PcModal v-if="showModal" @closeModal="closePCmodal" :title="modalTitle">
      <!-- loading -->
      <div slot="content" v-if="modalType == 0" class="buy_succes_modal"><modalLoading/></div>
      <!-- 交易已提交 -->
      <div slot="content" v-if="modalType == 1" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <img src="../../assets/image/arrow_top.png" alt="" style="width:3.8rem;margin:0 auto;margin-bottom:0.75rem">
          <p style="text-align:center;margin-bottom:20px">{{$t("lang.yitijiao")}}</p>
        </div>
        <div class="buy_succes_modal_btn" style="background:#000" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 交易成功 -->
      <div slot="content" v-if="modalType == 2" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <p>{{$t("lang.zhuzaochengong")}}</p>
        </div>
        <div class="buy_succes_modal_btn" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 失败 -->
      <div slot="content" v-if="modalType ==3" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <img src="../../assets/image/error_gray.png" alt="" style="width:3.8rem;margin:0 auto;margin-bottom:0.75rem">
          <p style="text-align:center;margin-bottom:20px">{{$t("lang.jiaoyishibai")}}</p>
        </div>
        <div class="buy_succes_modal_btn" style="background:#4A4A4A" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
    </PcModal>

    <!-- 铸造成功 -->
    <div class="cast_success_modal" v-if="castSuccess"> 
      <!-- <div class="cast_success_nft" :style="castNftList.length < 3 && { justifyContent: 'center' }"   castSuccess>
        <div
          class="cast_success_nft_item"
          v-for="(item,index) in castNftList" 
          :key="index+'castNftList'"
          :style="castNftList.length < 3 && index == 0 && { marginRight: '6.2rem' }"
        >
          <img src="" alt="">
          <div class="iteminfo">
            <p>MK</p>
            <p>#{{item}}</p>
          </div>
        </div>
      </div> -->

      <div class="mint_nft_success">
        <p class="mint_success_num">{{castNftList.length }}</p>
        <p class="mint_success_title">NFT</p>
        <div class="mint_nft_item" v-for="(item,index) in castNftList" :key="index+'castNftList'">
          <p>MKC</p>
          <p>#{{item}}</p>
        </div>
      </div>
      <div class="close_cast_success_modal" @click="$router.push('/mynft')">{{$t("lang.wodeNFT")}}</div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import PcModal from './../../components/PcModal.vue'
import modalLoading from '../../components/loading.vue';
import UmiButton from '../../components/UmiButton.vue';
import LoadingIcon from '../../components/loadingIcon.vue';
// 交易hash
let successOnce = false;
export default {
  name: "detail",
  components: {
    PcModal,
    modalLoading,
    UmiButton,
    LoadingIcon
  },
  data() {
    return {
      nftNum: '1',

      showModal: false,
      modalType: 0, // 0 loading, 1 已提交  2 成功 3 失败
      modalTitle: '',

      buyingStatus: 1, // 1可铸造 2 铸造中 3 铸造完成
      buyingStatusTxt:  this.$t("lang.zhuzao"),
      

      disbaleBuy: false,

      castNftList: [],
      castSuccess: false,
      canBuyNum: 3,

      intervalObj: null,
      countTime: {
        hour: '--',
        minutes: '--',
        seconds: '--'
      },
      castedNum: 0, // 已铸造数量
      canCastNum: 0, // 可铸造
    };
  },
  computed: {
    
  },
  watch: {
    nftNum() {
      const sum = this.nftNum * this.$store.state.NFTPrice;
      if(sum > (this.$store.state.ETHBalance *1)) {

      }
    },
    "$store.state.setNFTInfo" () {
      if(this.$store.state.NFTInfo.alreadyMint!=0 && this.$store.state.NFTInfo.release!=0) {
        this.disbaleBuy = (this.$store.state.NFTInfo.alreadyMint *1  == this.$store.state.NFTInfo.release *1 );
      }
    }
    
  },
  methods: {
    openLinkWallet() {
      this.$emit("openLinkWallet")
    },
    closePCmodal() {
      this.showModal = false;
      this.modalType = 0;
    },
    minus() {
      if(this.nftNum == 1) {
        return 
      }
      this.nftNum = this.nftNum - 1;
    },
    add() {
      if(this.nftNum == this.canBuyNum) {
        return 
      }
      this.nftNum = (this.nftNum * 1) + 1;
    },
    async buyNft() {
      if(this.disbaleBuy) {
        return 
      }
      if(this.buyingStatus != 1) {
        return
      }
      successOnce = false;
      this.showModal = true;
      this.buyingStatus = 2;
      const { NFT_contract,userAddress, NFTList } = this.$store.state;
      const gasPrice  = await this.web3js.eth.getGasPrice();
      
      const num = this.web3js.utils.toWei((this.nftNum * this.$store.state.NFTPrice)+''); //获取当前gas 价格
      
      NFT_contract.methods.mint(this.nftNum*1).send({from: userAddress,gasPrice,value: num })
      .on('transactionHash', (hash)=>{ // 交易hash
        this.modalType = 1;
        !this.showModal && (this.showModal = true);
      })
      .on('confirmation', (confirmationNumber, receipt)=>{ // 
      })
      .on('receipt', async (receipt)=>{ // 交易已广播
        if(!successOnce) {
          successOnce = true;
          let list = [];
          if((this.nftNum*1) > 1) {
            const itemlist = receipt.events.Transfer;
            for(let i = 0; i<itemlist.length ; i++) {
              
              list.push(itemlist[i].returnValues.tokenId)
            }
            
          } else {
            const returnValue = receipt.events.Transfer.returnValues;
            
            list = [
              returnValue.tokenId
            ]
          }
          this.castNftList = list;
          this.castSuccess = true;
          this.showModal = false;
          this.buyingStatus = 3;
          this.buyingStatusTxt = this.$t("lang.zhuzaowancheng");
          this.getInfo();
          this.getBalance();
          this.$store.commit('setNFTList',[...NFTList,...list])
        }
      })
      .on('error',(error, receipt)=>{ // 交易失败
        if(!successOnce) {
          this.modalType = 3;
          this.buyingStatus = 1;
          !this.showModal && (this.showModal = true);
          this.buyingStatusTxt =  this.$t("lang.zhuzao");
        }
      })
    },
    async getInfo() {
      const { NFT_contract } = this.$store.state;
      const maxSupply = await NFT_contract.methods.MAX_MINT_SUPPLY().call();
      const alreadyMint = await NFT_contract.methods.alreadyMintNftCount().call();
      const current = await NFTContract.methods.currentLeftNft().call();
      const release = await NFTContract.methods.releaseNft().call();
      this.$store.commit('setNFTInfo', { maxSupply, alreadyMint, current, release  })
      
      // this.castedNum = 8000 - res2;
      // this.canCastNum = res1;
      // if(res2 == 8000) {
      //   this.disbaleBuy = true;
      //   this.buyingStatus = 3
      // }
    },
    async getBalance() {
      const { userAddress,ETHBalance } = this.$store.state;
      const ETHres = await this.web3js.eth.getBalance(userAddress);
      const ETHBalance1 = this.$utils.mathpow(ETHres,this.$api.ETH_Demical);
      if(ETHBalance == ETHBalance1) {
        setTimeout(()=>{this.getBalance()},1000)
        return 
      }
      this.$store.commit('setBalance',{...balance,ETHBalance1})
    },
   
  },
  mounted() {
    if(!this.$store.state.userAddress) {
      this.$emit('openLinkWallet');
      return 
    }
    if(this.$store.state.NFTInfo.alreadyMint!=0 && this.$store.state.NFTInfo.release!=0) {
      this.disbaleBuy = (this.$store.state.NFTInfo.alreadyMint *1  == this.$store.state.NFTInfo.release *1 );
    }
    
  }
};
</script>
<style lang="less">
#castnft_page {
  padding-bottom: 10rem;
  .mintview {
    width: 60rem;
    height: 34rem;
    margin: 0 auto;
    background: url('./../../assets/images/mintbg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .leftInfo {
      position: absolute;
      left: 0;
      top: 0;
      width: 7.85rem;
      height: 31rem;
      background: rgba(133, 133, 133, 0.18);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .leftItem {
        margin-bottom: 2.5rem;
        text-align: center;
        p {
          color: #fff;
          font-weight: bold;
          &:first-child {
            font-size: 0.75rem;
            margin-bottom: 1rem;
          }
          &:last-child {
            font-size: 0.9rem;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 47rem;
      height: 3rem;
      background: rgba(0, 51, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2.55rem;
      .chooseview {
        font-size: 0.75rem;
        color: #fff;
        font-weight: 600;
        letter-spacing: 0.1rem;
        span {
          font-size: 1.2rem;
        }
      }
      .balance {
        font-size: 0.75rem;
        font-weight: 600;
        color: #DDE7FF;
      }
    }
    .input_loading_view {
      position: absolute;
      bottom: 4.25rem;
      left: 0;
      width: 100%;
      .loadingView {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 1.2rem;
        // background: rgba(255, 255, 255, 0.3);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .inputview {
        width: 100%;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 1.5rem;
          height: 1.5rem;
          cursor: pointer;
        }
        input {
          width: 10rem;
          height: 2rem;
          background: rgba(255, 255, 255, 0.3);
          font-size: 1.25rem;
          font-weight: 600;
          color: #FFFFFF;
          text-align: center;
          line-height: 2rem;
          margin: 0 0.45rem;
          border: none;
          outline: none;
        }
      }
    }
    .buybtnview {
      position: absolute;
      right: 0;
      bottom: -0;
      width: 13rem;
      height: 3rem;
      .btn {
        width: 100%;
        height: 100%;
        padding-left: 1.1rem;
        font-size: 0.75rem;
        font-weight: bold;
        color: #FFFFFF;
        text-align: left;
        line-height: 3rem;
        position: relative;
        img {
          position: absolute;
          right: 2.25rem;
          top: -0.65rem;
          width: 4.45rem;
          height: 4.45rem;
        }
      }
      .canMint {
        background: linear-gradient(to left, #0061FF,#0033FF);
        cursor: pointer;
        &:hover {
          background: linear-gradient(to left, #88ABFF,#5072FF);
        }
      }
      .minting {
        background: linear-gradient(to left, #0061FF,#0033FF);
        cursor: pointer;
        &:hover {
          background: linear-gradient(to left, #88ABFF,#5072FF);
        }
        cursor: not-allowed;
        display: flex;
        align-items: center;
        
        .minting_loading {
          margin-left: 5px;
          text-align: center;
        }
          .minting_loading > div {
            width: 8px;
            height: 8px;
            background-color: #fff;
          
            border-radius: 100%;
            display: inline-block;
            -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
          }
          
          .minting_loading .bounce1 {
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
          }
          
          .minting_loading .bounce2 {
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
          }
          
          @-webkit-keyframes sk-bouncedelay {
            0%, 80%, 100% { -webkit-transform: scale(0) }
            40% { -webkit-transform: scale(1.0) }
          }
          
          @keyframes sk-bouncedelay {
            0%, 80%, 100% { 
              -webkit-transform: scale(0);
              transform: scale(0);
            } 40% { 
              -webkit-transform: scale(1.0);
              transform: scale(1.0);
            }
          }

      }
      .balanceNotMint {
        background: linear-gradient(to left, rgba(136, 171, 255, 0),#88ABFF);
        cursor: not-allowed;
      }
    }
  }
}

.cast_success_modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 91;
  padding-top: 7rem;

  .mint_nft_success {
    width: 60rem;
    height: 26rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.95rem;
    .mint_success_num {
      margin-top: 0rem;
      font-size: 5rem;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }
    .mint_success_title {
      font-size: 1rem;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 1.5rem;
      margin-bottom: 4.3rem;
    }
    .mint_nft_item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2.9rem;
      padding: 0 2rem;
      box-sizing: border-box;
      p {
        flex: 1;
        font-size: 1.25rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1.8rem;
        text-align: right;
        word-break: break-all;
        &:last-child {
          margin-left: 3.35rem;
          text-align: left;
        }
      }
    }
  }

  .cast_success_nft {
    width: 60rem;
    height: 35.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url('./../../assets/images/mintSuccessBg.png') no-repeat;
    background-size: 100% 100%;
    .cast_success_nft_item {
      width: 16.6rem;
      height: 18.2rem;
      margin-bottom: 2rem;
      background: url('./../../assets/images/mynftitembg.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 9.66rem;
        height: 9.66rem;
        margin-bottom: 0.8rem;
      }
      .iteminfo {
        width: 9.66rem;
        display: flex;
        justify-content: space-between;
        p {
          font-size: 0.75rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
    }
  }
  .close_cast_success_modal {
    width: 22.5rem;
    height: 3rem;
    background: linear-gradient(270deg, #0061FF 0%, #0033FF 100%);
    text-align: center;
    line-height: 3rem;
    font-size: 1rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    &:hover {
      background: linear-gradient(270deg, #88ABFF 0%, #5072FF 100%);
    }
  }
}


.buy_succes_modal {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .buy_succes_modal_content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 11px;
  }
  .buy_succes_modal_content p {
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    color: #26493E;
    padding: 0 1rem;
  }
  .buy_succes_modal_btn {
    width: 100%;
    height: 3rem;
    background: linear-gradient(270deg, #0061FF 0%, #0033FF 100%);
    text-align: center;
    line-height: 3rem;
    color: #FFFFFF;
    font-size: 0.8rem;
    cursor: pointer;
  }
  .buy_succes_modal_btn:hover {
    background: linear-gradient(270deg, #88ABFF 0%, #5072FF 100%);
  }
}


</style>
  

<template>
  <div id="pcContainer" :style="$route.name == 'home' && {padding: 0}">

    <div class="header"  >
      <img src="./../../assets/images/logoicon.png" alt="logo" class="header_left" @click="$router.replace('/home')">
      <div class="header_mid">
        <!-- 锚链接区域 -->
        <div class="header_href">
          
          <p :class="tab==1 && 'choose'" @click="scrollPage('one')">{{$t("lang.zhuzao")}}</p>
          <p :class="tab==2 && 'choose'" @click="scrollPage('two')">MK</p>
          <p :class="tab==3 && 'choose'" @click="scrollPage('three')">{{$t("lang.luxiantu")}}</p>
          <p :class="tab==4 && 'choose'" @click="scrollPage('four')">{{$t("lang.tuandui")}}</p>
        </div>
        <!-- <div v-else class="header_href"></div> -->
        <div class="lang_view">
          <div class="lang_btn_view"><img src="./../../assets/images/lang_icon.png" alt="lang" class="lang_btn" @click="showLang = !showLang"></div>
          <div class="lang_select animated bounceOutRight" v-if="showLang">
            <div class="lang_select_item" v-for="(item,index) in langList" :key="index" @click="changeLang(item)">
              <p :style="item.value === langSelectValue && {color:'#fff'}">{{item.name}}</p>
              <!-- <img src="./../../assets/image/ok.png" alt="ok" v-show="item.value === langSelectValue"> -->
            </div>
          </div>
          <!-- lang 遮罩层 -->
          <div class="lang_select_bg" v-if="showLang" @click="showLang=false"></div>
        </div>
          <div class="wallet_view">
            <!--  -->
            <div class="link_btn" v-if="!$store.state.userAddress" @click="openDialog">
              <p>{{ connecting ? 'loading' :$t("lang.lianjieqianbao")}}</p>
            </div>
            <p class="address" v-else @click="$router.push('mynft')">
              <span>{{$store.state.userAddress.slice(0,4)}}...{{$store.state.userAddress.slice(-4)}}</span>
              <span  style="padding-left: 15px">MY NFT</span>
            </p>
          </div>
        </div>
    </div>

    <div class="routerView">
      <router-view @openLinkWallet="openDialog" :scrollEle="scrollele" @setScrollEle="clickTab" @scrollPage="scrollPage"/>  
    </div>

    <div class="footer_view">
      <div class="leftInfo"   style="flex:1">
        <p>Token Type：ERC-721A</p>
        <p>Blockchain：Ethereum</p>
        <p>File Hosting：IPFS</p>
      </div>
      <!-- <div v-else style="flex:1"></div> -->
      <img src="./../../assets/images/logoicon.png" alt="" class="footer_logo">
      <div class="footer_content" style="flex:1">
        
        <div class="footer_info">
          <div class="footer_link" v-if="$route.name!='home'" >
            <a href="https://twitter.com/MKclubHQ?s=09" target="_blank"><img src="./../../assets/images/footer_icon1.png" alt=""></a>
            <!-- <img src="./../../assets/images/footer_icon2.png" alt=""> -->
            <a href="https://discord.gg/YBd7VqEA6H" target="_blank"><img src="./../../assets/images/footer_icon3.png" alt=""></a>

            <a @click="openInvite"><img src="./../../assets/images/phonebottomlogo.png" alt=""></a>
            <!-- <img src="./../../assets/images/footer_icon4.png" alt=""> -->
          </div>
          <div v-else style="height:1rem"></div>
          <p>© 2022 MK LABS</p>
        </div>
      </div>
    </div>
    <!-- 链接钱包弹窗 -->
    <div v-show="showConnectWallet" class="connectWalletBg" @click="closeDialog">
      <div class="connect_wallet_view">
        <div class="connect_wallet_view_item">
          <img src="./../../assets/image/fox.png" alt="" @click="linkWallet('MetaMask')">
          <p>MetaMask</p>
          <p>{{$t("lang.lianjiematemask")}}</p>
        </div>
        <div class="connect_wallet_view_item" @click="linkWallet('WalletConnect')">
          <img src="./../../assets/image/wallet.png" alt="">
          <p>WalletConnect</p>
          <p>{{$t("lang.lianjiewallect")}}</p>
        </div>
      </div>
    </div>
    <!-- 钱包信息弹窗 -->
      <PcModal v-if="modalStatus" @closeModal="closeModal" :title="modalTitle">
      <!-- <p>Switch to the Ethereum NetWork</p> -->
        <div slot="content" v-if="modalType==1">
          <p class="switchNetwork_text">{{$t("lang.qingqiehuanwang")}}</p>
          <p class="switchNetwork_btn" @click="changeNetwork">{{$t("lang.qiehuanwangluo")}}</p>
        </div>
        <div slot="content" v-if="modalType==2">
          <p class="switchNetwork_text">连接请求被拒绝</p>
          <p class="switchNetwork_btn" @click="closeModal">关闭</p>
        </div>
      </PcModal>

      <PcModal v-if="showModal" @closeModal="closePCmodal" :title="$t('lang.yaoqinglianjie')">
        <div slot="content"  class="buy_succes_modal">
          <div class="buy_succes_modal_content">
            <div class="copyContent">
              <span>https://mkclub.io/#/home?from={{this.dealAddr()}}</span>
              <img src="../../assets/images/copyfill.png" alt="" @click="copyUrl">
            </div>
          </div>
          <div class="buy_succes_modal_btn" @click="copyUrl">{{$t("lang.fuzhi")}}</div>
        </div>
      </PcModal>
  </div>
</template>

<script>
import Vue from 'vue';
import Web3 from "web3";
import PcModal from "./../../components/PcModal.vue";
export default {
  name: "index",
  components: {
    PcModal
  },
  data() {
    return {
      tab: 1, // 控制顶部导航选中状态
      showConnectWallet: false, // 连接钱包弹窗
      showDraw: false, // 右侧个人中心
      // 钱包地址
      address: '',
      langList: [
        {name: 'English', value: 'en'},
        {name: '简体中文', value: 'zh'},
        {name: '日本语', value: 'jp'},
        {name: '한국어', value: 'ko'},
        {name: '繁体中文', value: 'zhtw'},
      ],
      langSelectValue: '', // 语言选择的值
      showLang: false, // 语言显示
      modalStatus: false, // 弹窗显示
      modalType: 1, // 弹窗内容类型
      modalTitle: '', // 弹窗标题
      connecting: false, // 连接钱包loading
      footerBg: true,

      scrollele: 'one',

      showModal: false,
    }
  },
  computed: {
    dealAddress() {
      return `${this.address.slice(0,4)}...${this.address.slice(-4)}`
    }
  },
  watch: {
   "$route.name"() {
    console.log(this.$route.name)
      const routeName = this.$route.name;
      if(routeName == 'mint' || routeName == 'mynft') {
        this.tab = 10;
      }
   }
  },
  methods: {
    dealAddr() {
      const addr = this.$store.state.userAddress;
      const str = addr.slice(2,addr.length)
      if(!addr) {
        return ''
      } 
      return '0x'+str.slice(0,4)+str.slice(-4)
    },
    openInvite() {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      this.showModal = true;
    },
    closePCmodal() {
      this.showModal = false;
    },
    copyUrl() {
      const Url2 = `https://mkclub.io/#/home?from=${this.dealAddr()}`
      var input = document.createElement('input'); // 创建input标签，只有input标签选中可以
      input.setAttribute('id', 'copyInput'); // 给input一个id属性
      input.setAttribute('value', Url2); // 给input一个value属性，属性值是变量span的文本
      document.getElementsByTagName('body')[0].appendChild(input); // body里面添加input实体
      document.getElementById('copyInput').select(); // 使用js去通过id找到并执行input实体的全部选中
      document.execCommand("Copy"); //原生copy方法执行浏览器复制命令
      document.getElementById('copyInput').remove(); // 为避免下次页面添加copy方法 所以这里copy完之后要移除input实体
      this.$message({
      type: 'success',
      message: this.$t("lang.fuzhichenggong")
      })
    },


    scrollPage (url) {
      const routeName = this.$route.name;
      if(routeName == 'mint' || routeName == 'mynft') {
        sessionStorage.setItem('tabval',url)
        this.$router.replace('home');
        return 
      }
      let anchorElement = document.getElementById(url);
      console.log(anchorElement)
      if(anchorElement) { 
        anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }
    },
    closeModal() {
      this.modalStatus = false;
    },
    changeNetwork() {
      this.$utils.switchNetwork(this.$api.chainId)
    },
 
    clickTab(val) {
      const routeName = this.$route.name;
      if(routeName == 'mint' || routeName == 'mynft') {
        sessionStorage.setItem('tabval',val)
        this.$router.replace('home');
        return 
      }
      this.tab = val;
      // this.closeDraw(); // 关闭 user draw
      
    },
    closeDialog(e) {
      if(e.target.className == 'connectWalletBg') {
        this.showConnectWallet = false;
      }
    },
    openDialog() {
      this.showConnectWallet = true;
    },
    openDraw() {
      this.showDraw = true;
    },
    closeDraw() {
      this.showDraw = false;
    },
    disconnect() {
      this.showConnectWallet = false;
      this.address = '';
      sessionStorage.clear();
      this.$store.commit('setUseraddress',null);
      this.closeDraw();
    },
    linkWallet(type) {
      if(type == "MetaMask") {
        if(!window.ethereum) {
          // 弹窗提示 安装 钱包
          // this.$alert("No MateMask,Please Install It!")
          return 
        }
      }
      this.connecting = true;
      this.$utils.linkWallet({
        type,
        successFn: async (data)=>{
          // this.address = data[0];
          this.showConnectWallet = false;
          const web3Object = new Web3(window.ethereum);
          const chainId = await web3Object.eth.getChainId();
          this.connecting = false;
          if(chainId != this.$api.chainId) {
            this.showConnectWallet = false;
            
            if(type=='MetaMask') {
              this.modalType = 1;
              this.modalTitle = this.$t("lang.wanluocuowu");
              this.modalStatus = true;
              sessionStorage.setItem('walletType',type);
            } else {
              this.$alert('Please switch wallet network', 'Network Error!');
            }
            return 
          }
          Vue.prototype.web3js = web3Object;
          this.showConnectWallet = false;
          sessionStorage.setItem('walletType',type);
          const params = {
            web3js: web3Object,
            userAddr: data[0]
          }
          this.$store.commit('setUseraddress',data[0])
          this.$store.dispatch("initContartInfo", params)
          // sessionStorage.getItem('',)
        },
        errorFn: ()=>{
          // Message
          this.showConnectWallet = false;
          this.modalType = 2;
          this.modalTitle = "连接失败";
          this.modalStatus = true;
          this.connecting = false;
        }
      });
    },
    
    changeLang(item) {
      this.langSelectValue = item.value;
      this.$i18n.locale = item.value;
      this.showLang = false;
    },
    getRoute() {
      const routeName = this.$route.name;
      if(routeName == 'mint' || routeName == 'mynft') {
        this.tab = 10;
      }
    }
  },
  mounted() {
    const walletType = sessionStorage.getItem('walletType');
    if(walletType) {
      this.linkWallet(walletType);
    }
    this.getRoute();
    // this.linkWallet();
  },
  created() {
    this.langSelectValue = this.$i18n.locale;
  }
  
};
</script>

<style lang="less">
#pcContainer {
  background: #000;
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7rem;
    background: #000;
    z-index: 9;
    .header_left {
      height: 3rem;
      cursor: pointer;
      position: absolute;
      left: 4.45rem;
      top: 50%;
      margin-top: -1.5rem;
    }
    .header_mid {
      width: 60rem;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      .header_href {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        p {
          text-align: center;
          font-size: 1rem;
          color: #fff;
          margin-right: 5.6rem;
          cursor: pointer;
          position: relative;
          &:last-child {
            margin-right: 6.7rem;
          }
          &::before,&::after {
            content: '';
            /* 将开始的宽度设置为0,这样才会展示出过渡效果 */
            width: 0;
            height: 2px;
            background-color: #fff;
            /* 设置原本定位 */
            position: absolute;
            top: 100%;
            left: 50%;
            /* 设置过渡效果 */
            transition: all 0.8s;
          }
          &:hover::before {
            /* 实现向左展开效果 */
            /* 鼠标移入改变宽度 */
            width: 50%;
            /* 鼠标移入改变定位位置 */
            left: 0px;
          }
          &:hover::after {
            width: 50%;
            right: 0px;
          }
        }
        .choose {
          font-weight: 600;
          border-bottom: 2px solid #fff;
        }
      }
      
      .lang_view {
        position: relative;
        .lang_select_bg {
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 9;
          background: rgba(0, 0, 0, 0.4);
        }
        .lang_select {
          position: absolute;
          top: 3rem;
          right: 0px;
          z-index: 10;
          width: 7.9rem;
          height: 11.3rem;
          background: #000000;
          padding: 0.5rem 0;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .lang_select_item {
          margin-bottom: 1rem;
          text-align: center;
          cursor: pointer;
          &:active {
            color: #FFFFFF;
          }
          &:hover {
            color: #FFFFFF;
            border-radius: 2rem;
          }
          p {
            color: #979797;
            font-size: 0.6rem;
            height: 100%;
            line-height: 1.5rem;
          }
          img {
            flex-shrink: 0;
            width: 1.2rem;
          }
        }
        .lang_btn_view {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1.75rem;
          cursor: pointer;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
          .lang_btn {
            width: 1rem;
            height: 1rem;
          }
        }
      }

    }
    
    
    .wallet_view {
      overflow: hidden;
      min-width: 9.85rem;
      height: 2rem;
      background: url('./../../assets/images/phonelinkwalletbg.png') no-repeat;
      background-size: 100% 100%;
      &:hover {
        background: url('./../../assets/images/phonelinkwalletbg_hover.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .link_btn {
      width: 100%;
      height: 100%;
      font-size: 1rem;
      color: #fff;
      line-height: 2.1rem;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 2.1rem;
      padding: 0 1.5rem;
    }
    .address {
      width: 100%;
      height: 100%;
      font-size: 1rem;
      color: #fff;
      text-align: center;
      line-height: 2.1rem;
      cursor: pointer;
      padding: 0 1.5rem;
    }
  }

.routerView {
  flex: 1;
  // padding: 0 2.5rem;
  padding-top: 7rem;
  box-sizing: border-box;
  width: 100%;
}
.footer_view {
  padding: 0 5.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 12.9rem;
  border-top: 1px solid #0033FF;
  border-bottom: 1rem solid #0033FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftInfo {
    font-size: 0.6rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #9A9A9A;
    line-height: 0.9rem;

  }
  .footer_logo {
    width: 8.5rem;
    // height: 3.75rem;
    flex-shrink: 0;
  }
  .footer_content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    
    .footer_info {
      .footer_link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        a {
          width: 1rem;
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
        }
        img {
          width: 1rem;
          cursor: pointer;
          
        }
      }
      p {
        margin-top: 1rem;
        font-size: 0.6rem;
        font-weight: 400;
        color: #9A9A9A;
        text-align: right;
      }
    }
  }
  
}

.connectWalletBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  .connect_wallet_view {
    width: 30rem;
    height: 20rem;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  }
  .connect_wallet_view_item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  .connect_wallet_view_item:first-child {
    border-bottom: 1px solid #E1EDE8;
  }
  .connect_wallet_view_item img {
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .connect_wallet_view_item p {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #26493E;
  }
  .connect_wallet_view_item p:last-child {
    font-size: 1rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #93BCAF;
    margin-top: 9px;
  }
}


.switchNetwork_text {
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;
  text-align: left;
  margin-top: 2.4rem;
  margin-bottom: 4.3rem;
  text-align: left;
  width: 100%;
  padding-left: 1rem;
}
.switchNetwork_btn {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  background: linear-gradient(270deg, #0061FF 0%, #0033FF 100%);
  border-radius: 0rem;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  margin: auto;
  cursor: pointer;
}
.switchNetwork_btn:hover {
  background: linear-gradient(270deg, #0061FF 0%, #0033FF 100%);
}
}
</style>

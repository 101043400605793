import WalletConnectProvider from "@walletconnect/web3-provider";

// import QRCodeModal from "@walletconnect/qrcode-modal";
import api from './../assets/js/API'
import axios from 'axios'
let $BigNumber = require("bignumber.js");
const utils = {
  formatNum(str) {
    var newStr = "";
    var count = 0;

    if (str.indexOf(".") == -1) {
      for (var i = str.length - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
          newStr = str.charAt(i) + "," + newStr;
        } else {
          newStr = str.charAt(i) + newStr;
        }
        count++;
      }
      str = newStr + ".00"; //自动补小数点后两位
      // console.log(str)
    }
    else {
      for (var i = str.indexOf(".") - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
          newStr = str.charAt(i) + "," + newStr;
        } else {
          newStr = str.charAt(i) + newStr; //逐个字符相接起来
        }
        count++;
      }
      str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
      return str
    }
  },
  // bigNumber 转换， 参数： （值、精度）,去掉精度、
  mathpow(data, num) {
    //精度
    var n1;
    if (!data) {
      n1 = $BigNumber(data);
    } else {
      n1 = $BigNumber(data.toString());
    }
    var n2 = $BigNumber(Math.pow(10, num ? num : 8));

    var res = n1.div(n2);
    return res.toString();
  },
  // 值 精度， 给值添加精度
  mathlog(data, num) {
    return $BigNumber(data).multipliedBy(Math.pow(10, num)).toString();
  },
  // 计算加法
  plus(a, b) {
    if (a !== '' && b !== '') {
      const n1 = $BigNumber(a);
      const n2 = $BigNumber(b);
      return (n1.plus(n2)).toString()
    } else {
      return 0
    }
  },
  // 计算减法
  minus(a, b) {
    if (a != '' && b != '') {
      const n1 = $BigNumber(a);
      const n2 = $BigNumber(b);
      return (n1.minus(n2)).toString()
    } else {
      return 0
    }
  },
  // 转为bignumber 计算乘法
  multiplication(a, b) {
    if (a != '' && b != '') {
      const n1 = $BigNumber(a);
      const n2 = $BigNumber(b);
      return (n1.multipliedBy(n2)).toString()
    } else {
      return 0
    }
  },
  // 计算除法
  division(a, b) {
    if (a != '' && b != '') {
      const n1 = $BigNumber(a);
      const n2 = $BigNumber(b);
      return (n1.div(n2)).toString()
    } else {
      return 0
    }
  },
  // 切换网络
  async switchNetwork(id) {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{
        chainId: '0x' + id
      }]
    })
  },
  // 网页钱包授权
  async linkWallet({ type, successFn, errorFn }) {
    if (type == 'MetaMask') {
      if (window.ethereum) {
        // console.log('window.ethereum')
        try {
          const data = await window.ethereum.request({ method: 'eth_requestAccounts' });
          // console.log('data.data',data)
          successFn(data);
        } catch (e) {
          // console.log('eeeee',e)
          errorFn(e)
        }
        ethereum && ethereum.on("accountsChanged", function (accounts) {
          window.location.reload();
        });
        ethereum && ethereum.on("chainChanged", function (accounts) {
          window.location.reload();
        });
      }
    } else { // walletconnect

     const provider = new WalletConnectProvider({
        rpc: {
          4: "https://rinkeby.infura.io/v3/",
        },
        "chainId": 4,//需要连接的区块链id
        "networkId": 4,
        qrcode: true,//二维码是否开启
      });

      const res = await provider.enable();
      window.ethereum = provider;
      // console.log(res)
      successFn(res);

      // Subscribe to accounts change
      provider.on("accountsChanged", (accounts) => {
        successFn(accounts);
        window.location.reload();
      });

      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        // successFn(accounts,chainId);
        window.location.reload();
      });

      // Subscribe to session disconnection
      provider.on("disconnect", (code, reason) => {
        sessionStorage.clear();
        window.location.reload();
      });
    }

  },
  async addUMIUSDC(type, img) {
    const umiData = {
      address: '0x43710f201183008EAf8170E729481289D52A695B',
      symbol: 'UMI',
      decimals: 8,
      image: 'https://cdn.umi.cash/UMI.svg'
    }
    const usdcData = {
      address: '0x541A0aCaBD7fFdBB4e2177Ed0686799B48Be8650',
      symbol: 'USDC',
      decimals: 8,
      image: 'https://cdn.umi.cash/UMI.svg'
    }
    // console.log(type == 'umi' ? umiData : usdcData)
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: type == 'umi' ? umiData : usdcData,
        },
      });

      if (wasAdded) {
        // console.log('Thanks for your interest!');
      } else {
        // console.log('Your loss!');
      }
    } catch (error) {
      // console.log(error);
    }
  },

  // 邀请链接的请求
  uploadInvite(user) {
    const url = window.location.href;
    let urlStr = url.split('?')[1]
    if(!urlStr) {
      return 
    }
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for(let i = 0,len = paramsArr.length;i < len;i++){
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split('=')
        obj[arr[0]] = arr[1];
    }
    if(!obj.from) {
      return 
    }
    axios.get(`https://node.umidao.org/data.php?domain=mkclub.io&from=${obj.from}&to=${user}`);

  }

}
export default Object.freeze(utils)
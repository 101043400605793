import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/pc/home'
import pcindex from '@/views/pc/index'
import mynft from '@/views/pc/mynft'
import details from '@/views/pc/details'
import castnft from '@/views/pc/castNft'

import h5index from '@/views/h5/index'
import h5home from '@/views/h5/home'
import h5mynft from '@/views/h5/mynft'
import h5details from '@/views/h5/details'
import h5castnft from '@/views/h5/castNft'

import test from '@/views/pc/test'

Vue.use(Router)

const pageRouter =  new Router({
  routes: [
    {
      path: '/',
      name: 'PC首页',
      component: home,
      redirect: '/home',
      children:[
        {
          path: '/home',
          name: 'home',
          component: details,
        },
        // {
        //   path: '/details',
        //   name: 'details',
        //   component: details,
        // },
        {
          path: '/mynft',
          name: 'mynft',
          component: mynft,
        },
        {
          path: '/mint',
          name: 'mint',
          component: castnft,
        },
      ]
    },
    {
      path: '/h5home',
      name: 'H5首页',
      component: h5home,
      children:[
        {
          path: '/h5home',
          name: 'h5home',
          component: h5details,
        },
        {
          path: '/h5mynft',
          name: 'h5mynft',
          component: h5mynft,
        },
        // {
        //   path: '/h5details',
        //   name: 'h5details',
        //   component: h5details,
        // },
        {
          path: '/h5mint',
          name: 'h5mint',
          component: h5castnft,
        },
      ]
    },
    
  ]
})
pageRouter.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
});
export default pageRouter;

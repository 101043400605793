// 英文   key由 文字中的四个字的拼音组成
export const lang = {
  
  'guanbi': 'Close',
  "zhuzaochenggong":"Mint Succeed",
  'yitijiao': 'Submitted',


  'gongxi':'Congratulations',
  'wodeNFT':'My NFT',
  
  'duankailianjie':'Disconnect',
  'lianjieshiba':'Connection Failed',
  'lianjiebeijujie':'connection failed, connection is refused',
  'wanluocuowu':'Network Failed',
  'qingqiehuanwang':'Network failed, please switch to Mainnet.',
  'qiehuanwangluo':'Switch to Mainnet',

  'jiaoyishibai':'Transaction Failed',
  'jiaoyijujie':'transaction request is denied',
  
  "lianjieqianbao": "Connect Wallet",
  "dianjijinru": "Enter",
  "lianjiematemask": "Connect your MetaMask Wallet",
  "lianjiewallect":"Connect WalletConnect",
  "zhuzao":"Mint",
  "luxiantu":"Roadmap",
  "tuandui":"Team",
  "meirixiangliang":" Daily Limited Supply   Enjoy Early-bird Discount",
  "mkcdengdai":"9000MKC Waiting for Mint",
  "dianjizhuzao":"Mint",
  "guanyumk":" About MK",

  "about1":"It has been ten thousand years since MK got the Sutra from the Western Heaven.",
  "about2":"With the passage of time, everything changes. Humans, the master of the earth, have gone further and further on the road to the liberation of human nature. Human nature has replaced divinity, material desires have replaced abstinence, and secular happiness has replaced sacred faith. Humans’greedy nature has made all things on earth loveless. Humans' hatred, involution, warfare, racial discrimination, virus rampant... Human civilization is on the verge of collapse.",
  "about3": "In order to save humans, MK turns into 10,000 Avatars and fall into the crowd. These Chosen 10,000 MK Avatars need to take on the test of good and evil of human nature every day before they can finally become MK. 10,000 MKs gather together to protect the earth.",
  "about4": "Good and evil are just one thought. The 'one thought Zen spell' brought by MK Avatars when they come into the world will help The Chosen MKs to withstand the test of complex human nature.",
  "about5": "The Chosen MKs are divided into five ethnic groups, each of them has humans’ weaknesses and advantages：",
  "about6": "Soft Cute Group – They have a childlike innocence that don't want to grow up, hate the complex and cruel adult society, and return to the simple and kind essence of human nature.",
  "about7": "Street Group – They resist, rebel and shout against social injustice and imprisonment. Even at the bottom, they still have the courage to touch heaven.",
  "about8": "Magic Group – They are delicate in mind, rich in emotion, full of fantasy, many of them have a keen Sixth Sense and the ability to predict the future.",
  "about9": "They are wild and uninhibited in life. They love freedom, and challenge their limits and courage with adventure.",
  "about10": "Interstellar Group – They have a desire for knowledge about unsolved things, and feel that living in this world is to explore the future.",
  
  "zhuzaoguize":"Mint Rule",
  "fenpeijizhi":"Distribution",
  "baoliu":"Reservation",
  "1000ge":"1000",
  "9000ge":"9000",
  "diyicishifang":"2000 NFTs are released for the first time, and 700 NFTs are released at 3:00 p.m. ET every Tuesday after 72 days, steadily release for 10 weeks.",
  "zhuzaojiage":"Mint Price",
  "MINTshuliang":"Mint Amount",
  "1_72tian":"Day1-Day72",
  "MINTjiage":"MINT Price",
  "73_108tian":"Day73-Day108",
  "109tian":"109 Days Later",
  "zhanghurengoucishu":"One account only has one chance to buy 1-3 NFTs（Min 1, Max 3）",
  "luxiantu":"Roadmap",
  "luxiantu1":"Planning to build MK Labs Metaverse world according to the Roadmap",
  "luxiantu2":"Build our Twitter, Discord Official Media Matrix and Community",
  "luxiantu3":"Launch MK Oriental Tide Play Derivatives",
  "luxiantu4":"Build our YouTube Channel and TikTok Official Media",
  "luxiantu5":"Offline Tide Play Joint Exhibition Tour, and Launch Limited Edition T-shirts, Sneakers and Other Goodies",
  "luxiantu6":"Launch MK Boutique--Exclusive / Limited Edition Collection",
  "luxiantu7":"Offline Digital Art Auction, and Place MK Statues in Landmark Buildings of Well-known Cities",
  "luxiantu8":"Release MK video Jointly with Well-known Producers and Organize Offline Member Salons",
  "luxiantu9":"OpenSea Buy-back, and Hold a Series of Jointly activities with the well-known brands holding MK",
  "luxiantu10":"Purchase Virtual Land to Display Meta Space and Start GameFi Development",
  "luxiantu11":"Gala&Meetups Celebration & Offline Member Salons, and Announce the roadmap of Airdropping other 3 NFT series towards MKC members",
  "tuanduijieshao":"Introduction of Development and Operation Team",
  "saicheshou":"Racing Driver",
  "saicheshou1":"The ultimate travel and adventure enthusiast! A practitioner who is always on the road.",
  "shejishi":" Crazy designer with a powerful and unconstrained style",
  "shejishi1":" With lax lifestyle, he is dissatisfied with all aspects. Thousands of times he hoped to restart, and now,          he is given the chance. How to grasp it? What course to follow? and how to make life perfect?!",
  "faxingzhe":"The earliest issuers of Mobile game",
  "faxingzhe1":"Addicted to French wine & Cuban cigar, and also a loyal fan of Scottish highland whisky.",
  "jinrongzhuanjia":"Blockchain technology and financial expert",
  "jinrongzhuanjia1":"Pets lover, especially the king cobra, accompanied with a yellow dog.",
  "dukadi":"Ducati locomotive lover",
  "dukadi1":"Due to the experience of code career, becoming the excellent Dealer of Las Vegas casinos.",
  "yuanzhuming":"Blockchain Native ",
  "yuanzhuming1":"Wearing m65, carrying ak47, freely convert just and evil in African prairie.",
  "mincheng":"Name",
  "shifang":"Release",
  "jiage":"Price",
  "yixuan":"Selected",
  "zongji":"sum",
  "yue":"Balance",
  "zhuzaozhong":"Minting",
  "yuebuzu":"Insufficient",
  "dengdaiqueren":"Waiting for Confirmation",
  "zhuaaowancheng":"Mint Done",

  "yaoqinglianjie": 'Invitation link',
  "fuzhi": "Copy",
  "fuzhichenggong": "Copy Succeed",
  "dakaiqianbao": "Open your Decentralized Wallet",
  "lianjiefuzhi": "Paste the copied website link to the Browser in Wallet and go to mint"

}
<template>
  <div class="castnft_page">
    <div class="scroll-item" id="one" style="height: 600px;background: #3a8ee6;">一层</div>
    <div class="scroll-item" id="two" style="height: 600px;background: red;">二层</div>
    <div class="scroll-item" id="three" style="height: 600px;background: #42b983">三层</div>
    <div class="scroll-item" id="four" style="height: 600px;background: yellow;">四层</div>
  </div>
</template>
<script>
export default {
  name: "detail",
  props: ["scrollEle"],
  data() {
    return {
      elementScroll: {one: 0, two: 0, three: 0, four: 0}
    };
  },
  methods: {
    pageScroll(e) {
      const scrollLen = e.srcElement?.documentElement?.scrollTop;
      
      console.log(scrollLen)
      if(scrollLen==0) {
        this.$emit('setScrollEle',1)
      } else if(scrollLen < this.elementScroll['two']) { // one
        this.scrollEle!=1 && this.$emit('setScrollEle',1)
      } else if(this.elementScroll['two'] < scrollLen && scrollLen < this.elementScroll['three']) { // two

        this.scrollEle!=2 && this.$emit('setScrollEle',2)
      } else if(this.elementScroll['three'] < scrollLen && scrollLen < this.elementScroll['four']) { // three
        this.scrollEle!=3 && this.$emit('setScrollEle',3)
      }  else { // partners
        this.scrollEle!=4 && this.$emit('setScrollEle',4)
      }
    }
  },
  mounted() {
    setTimeout(()=>{
        for( let item in this.elementScroll) {
          if(item == 'one') {
            this.elementScroll[item] = 0;
          } else {
            this.elementScroll[item] = Number(document.getElementById(item)?.offsetTop) - 80;
          }
          
        }
        console.log(this.elementScroll)
      },500)
    window.addEventListener('scroll',this.pageScroll)
  },
 
};
</script>
<style lang="less">
.castnft_page {
  width: 60rem;
  margin: 0 auto;
}
</style>
  
<template>
  <div id="mynft_phone">
    <!-- <p class="mynftTitle">{{$t("lang.wodeNFT")}}</p> -->

    <div class="mynftList" v-if="nftList.length">
      <div class="listItem" v-for="(item,index) in nftList" :key="index">
        <img src="./../../assets/images/mynftitembg_phone.png" alt="" class="itemBg">
        <div class="itemContent">
          <img :src="item.IPFS" alt="">
          <div class="info">
            <p>MK</p>
            <p>#{{item.id}}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="width:100%;height:200px"></div>

    <div class="opensea"><a href="https://opensea.io/account" target="_blank">OPENSEA</a></div>
    <div style="height: 102px"></div>
  </div>
</template>
<script>

import modalLoading from "../../components/loadingIcon.vue";
import axios from 'axios';
// 交易hash
export default {
  name: "detail",
  components: {
    modalLoading
  },
  data() {
    return {
      nftList: [],
      loading: true
    };
  },
  
  watch: {
    '$store.state.NFTList'() {
      this.getList();
    }
  },
  methods: {
    async getList() {
      this.loading = true;
      const { NFT_contract } = this.$store.state;
      const list = this.$store.state.NFTList;
      console.log('zzzzz',list)
      const result = []
      try {
        if(list.length) {
          for(let i = 0; i<list.length ; i++) {
            const imgData  = await NFT_contract.methods.tokenURI(list[i]).call();
            const jsonRes = await axios.get(imgData.replace('https://ipfs.io','https://mk.mypinata.cloud'));
            
            result.push({ IPFS: `https://mk.mypinata.cloud/ipfs/${jsonRes.data.image}`, id: list[i]})
            console.log(result)
          }
        }
      } catch (error) {
        
      }
      this.loading = false;
      this.nftList = result;
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<style lang="less">
#mynft_phone {
  .logo {
    margin-bottom: 43px;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: 55px;
    }
  }
  .mynftTitle {
    font-size: 14px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 42px;
  }
  .mynftList {
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    .listItem {
      width: 50%;
      position: relative;
      .itemBg {
        width: 100%;
        height: auto;
      }
      .itemContent {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 77px;
          height: 77px;
          margin-bottom: 7px;
        }
        .info {
          width: 77px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 12px;
            font-weight: 600;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .opensea {
    margin: 0 60px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    
    background: linear-gradient(to bottom, #0061FF, #0033FF);
    a {
      width: 100%;
      color: #FFFFFF;
      text-decoration: none;
      display: block;
      margin: 0;
    }
  }
}
</style>

<template>
 <div id="indexphonePage" @click="$router.push('h5home')">
  <!-- <div class="content">
    <img src="./../../assets/images/indexlogo_phone.png" alt="">
    <div class="btn" @click="$router.push('h5home')">
      <p>{{$t("lang.dianjijinru")}}</p>
      <img src="./../../assets/images/btn_right.png" alt="" class="arrow">
      <img src="./../../assets/images/btnlx.gif" alt="" class="icon">
    </div>
  </div> -->
  <img src="./../../assets/images/indexlogo_phone.png" alt="">
 </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
     
    };
  },
  computed: {
    
  },
 
}
</script>

<style lang="less">
#indexphonePage {
  width: 100%;
  height: 100vh;
  background: url('./../../assets/images/indexbg_phone.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 210px;
  }
  // .content {
  //   width: 100%;
  //   height: 400px;
  //   background: url('./../../assets/images/indexbg_phone.png') no-repeat;
  //   background-size: 100% 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   position: relative;
  //   margin-bottom: 100px;
  //   img {
  //     width: 98px;
  //     height: auto;
  //   }
  //   .btn {
  //     position: absolute;
  //     bottom: -58px;
  //     left: 0;
  //     width: 100%;
  //     height: 58px;
  //     background: linear-gradient(270deg, #0061FF 0%, #0033FF 100%);
  //     padding-left: 21px;
  //     line-height: 58px;
  //     color: #FFFFFF;
  //     display: flex;
  //     align-items: center;
  //     p {
  //       font-size: 14px;
  //       font-weight: bold;
  //       text-align: left;
  //     }
  //     .arrow {
  //       width: auto;
  //       height: 15px;
  //       margin-left: 12px;
  //     }
  //     .icon {
  //       position: absolute;
  //       right: 14px;
  //       top: -13px;
  //       width: 86px;
  //       height: 86px;
  //     }
  //   }
  // }
}
</style>

    import Vue from 'vue';
    import Vuex from 'vuex';
    import Web3 from 'web3';
    import API from '../assets/js/API';
    import abi from './../assets/js/XRC20abi'
    import utils from './../utils/utils';
    Vue.use(Vuex);
    const state = {
      userAddress: '',
      NFT_contract: null,

      ETHBalance: '0.00',
      
      NFTBalance: 0,

      NFTPrice: 2.0,
      
      NFTInfo: {
        maxSupply: 0,
        alreadyMint: 0,
        current: 0,
        release: 0
      },

      NFTList: [],
    };
    
    const store = new Vuex.Store({
      state,
      mutations: {
        setUseraddress(state,data) {
          state.userAddress = data
        },
        setNFT_Contract(state,data){
          state.NFT_contract = data;
        },
        setETHBalance(state,data) {
          state.ETHBalance = data
        },
        setNFTPrice(state,data) {
          state.NFTPrice = data
        },
        setNFTInfo(state,data) {
          state.NFTInfo = data
        },
        setNFTBalance(state,data) {
          state.NFTBalance = data
        },
        setNFTList(state,data) {
          state.NFTList = data;
        },
        
      },
      actions: {
  
        async initContartInfo(state,data) {
          const { web3js,userAddr } = data;
          utils.uploadInvite(userAddr);
          // ETH
          const ETHres = await web3js.eth.getBalance(userAddr);
          console.log(ETHres)
          const ETHBalance = utils.mathpow(ETHres,API.ETH_Demical);

          // NFT 
          const NFTContract = new web3js.eth.Contract(abi.nftabi, API.NFT_Addr, { from: userAddr });

          const NFTBalacnce = await NFTContract.methods.balanceOf(userAddr).call();

          const res1 = await NFTContract.methods.getCurrentPrice().call();

          const maxSupply = await NFTContract.methods.MAX_MINT_SUPPLY().call();
          const alreadyMint = await NFTContract.methods.alreadyMintNftCount().call();
          const current = await NFTContract.methods.currentLeftNft().call();
          const release = await NFTContract.methods.releaseNft().call();

          let nftList = []; // nft 列表
          if(!!(NFTBalacnce * 1)) {
            for( let i = 0;i<NFTBalacnce;i++) {
              const res = await NFTContract.methods.tokenOfOwnerByIndex(userAddr,i).call({from: userAddr});
              nftList.push(res); 
            }
          }

          

          state.commit('setNFT_Contract',NFTContract);

          store.commit('setNFTPrice',(utils.mathpow(res1,18)*1).toFixed(18))
          // state.commit('setDAO_Contract',DAOContract);
          state.commit('setETHBalance',(ETHBalance*1).toFixed(3));

          state.commit('setNFTInfo', { maxSupply, alreadyMint, current, release })

          state.commit('setNFTList',nftList);

          state.commit('setNFT_Contract',NFTContract);

          state.commit('setNFTBalance',NFTBalacnce);
          // state.commit('setLockInfo',{start: lockInfo.daoStartDay, totalRewards: lockInfo.totalReward});
        }
      }
    });
    export default store

// 中文  key由 文字中的四个字的拼音组成
export const lang = {

  'guanbi': '关闭',
  "zhuzaochenggong":"铸造成功",
  'yitijiao': '已提交交易',


  'lianjieqianbao':'连接钱包',
  'tuandui':'团队',
  'luxiantu':'路线图',
  'yue':'余额',
  'gongxi':'恭喜',
  'wodeNFT':'我的NFT',
  'yuebuzu':'余额不足',
  'querenzheci':'确认这次交易在你的钱包',
  'wodeqianbao':'我的钱包',
  'duankailianjie':'断开连接',
  'lianjieshiba':'连接失败',
  'lianjiebeijujie':'连接失败，连接被拒绝',
  'wanluocuowu':'网络错误',
  'qingqiehuanwang':'网络错误，请切换到主网。',
  'qiehuanwangluo':'切换到主网',
  'pizhunshibai':'批准失败',
  'pizhunjujie':'批准请求被拒绝',
  'jiaoyishibai':'交易失败',
  'jiaoyijujie':'交易请求被拒绝',
  'lianjieMetamask':'连接到你的MetaMask钱包',
  'lianjieWalletConnect':'连接到WalletConnect',
  // 
  "linajieqianbao":"连接钱包",
  "dianjijinru":"点击进入",
  "lianjiematemask":"连接到你的MetaMask钱包",
  "lianjiewallect":"连接到WalletConnect",
  "zhuzao":"铸造",
  "luxiantu":"路线图",
  "tuandui":"团队",
  "meirixiangliang":"每日限量供应 早买享受优惠",
  "mkcdengdai":" 9000 MKC等待铸造",
  "dianjizhuzao":"点击铸造",
  "guanyumk":"关于MK",

  "about1":"自MK从西天取得真经归来已是万年。",
  "about2":"斗转星移，世事变迁，地球的主宰者人类向人性解放的道路上越走越远，人性取代神性，物欲取代禁欲，世俗之乐取代神圣信仰。人类贪婪的本性令地球万物生无可恋。人类间相互仇恨，内卷，战争，种族歧视，病毒肆虐……人类文明面临崩溃的边缘。",
  "about3": "为了拯救人类，MK幻化为一万个分身，落入人群中。这一万个MK分身入定的天选之子，每日需要接受人性善与恶的考验，最终方能修成MK。一万MK集结，共同守护地球。",
  "about4": "善恶只不过一念之间，MK分身降临时世间带来的“一念禅语”，会帮助天选之子们经受住复杂人性的考验。",
  "about5": "MK天选之子分为五个族群，他们各有人性的弱点与优点：",
  "about6": "软萌族-拥有一颗不愿意长大的童心，厌恶复杂而残酷的成人社会，回归单纯善良的人性本质。",
  "about7": "街头族-向社会不公与禁锢发出反抗、叛逆和呐喊，即使深处底层，仍有勇气触碰天堂。",
  "about8": "魔幻族-心思细腻、情感丰富、富于幻想，有不少人拥有敏锐的第六感与预知未来的能力。",
  "about9": "机车族-一生狂放不羁爱自由，用冒险的旅程来挑战自我的极限和心中的勇气。",
  "about10": "星际族-对于未解的事物都发出求知的欲望，感觉生存在这个世界上就是来探索未来的。",

  "zhuzaoguize":"铸造规则",
  "fenpeijizhi":"分配机制",
  "baoliu":"保留",
  "1000ge":"1000个",
  "9000ge":"9000个",
  "diyicishifang":"第一次释放2000个，72天后每周二凌晨3点释放700个，持续释放10周。",
  "zhuzaojiage":"铸造价格",
  "MINTshuliang":"MINT数量",
  "1_72tian":"1-72天",
  "MINTjiage":"MINT价格",
  "73_108tian":"73-108天",
  "109tian":"109天后",
  "zhanghurengoucishu":"一个账户认购1次，1次1-3个（最小1个，最大3个）",
  "luxiantu":"路线图",
  "luxiantu1":"计划根据路线图逐步构建MK Labs元宇宙世界",
  "luxiantu2":"拥有自己的twitter、discord官方媒体矩阵及社群",
  "luxiantu3":"推出MK Oriental潮玩衍生品",
  "luxiantu4":"拥有我们自己的YouTube频道及Tiktok官方媒体",
  "luxiantu5":"线下潮玩IP联合巡展，推出限量版T恤、球鞋及其他好物",
  "luxiantu6":"推出MK Boutique--独家商品/限量版收藏品",
  "luxiantu7":"线下数字艺术品拍卖，在知名城市地标建筑物摆设MK雕像",
  "luxiantu8":"联合知名制作人发布MK video，组织线下会员沙龙",
  "luxiantu9":"opensea回购，联合持有MK的知名品牌举办系列联名活动",
  "luxiantu10":"购买专属的虚拟土地展示元宇宙空间，启动GameFi开发",
  "luxiantu11":"Gala&Meetups庆典与线下会员沙龙，公布面向MKC会员空投3个NFT的路线图",
  "tuanduijieshao":"开发及运营团队介绍",
  "saicheshou":"赛车手",
  "saicheshou1":"极致旅行探险爱好者！一直在路上的修行者",
  "shejishi":"天马行空的疯狂设计师",
  "shejishi1":"于平时极不着调，日子过得很不舒意。千万次希望重来一次，终于上帝给了他这个机会，如何把握，何去何从，怎样才能让人生不再留遗憾？！",
  "faxingzhe":"Mobile game的最早一批发行者",
  "faxingzhe1":"热爱法国红酒、古巴雪茄，是苏格兰高地威士忌的忠实粉丝。",
  "jinrongzhuanjia":"区块链技术与金融专家",
  "jinrongzhuanjia1":"宠物爱好者、享受眼镜王蛇的回头杀，每天一只大黄狗为伴。",
  "dukadi":"杜卡迪机车爱好者",
  "dukadi1":"凭借码农的经验一举成为拉斯维加斯赌场的神走位荷官。",
  "yuanzhuming":"区块链行业原住民",
  "yuanzhuming1":"身穿m65，肩挎ak47，非洲大草原黑白两道转换自如。",
  "mincheng":"名称",
  "shifang":"释放",
  "zhuzao":"铸造",
  "jiage":"价格",
  "yixuan":"已选",
  "zongji":"总计",
  "yue":"余额",
  "zhuzaozhong":"铸造中",
  "yuebuzu":"余额不足",
  "dengdaiqueren":"等待确认",
  "zhuzaowancheng":"铸造完成",

  "yaoqinglianjie": '邀请链接',
  "fuzhi": "复制到粘贴板",
  "fuzhichenggong": "复制成功",
  "dakaiqianbao": "打开您的去中心化钱包",
  "lianjiefuzhi": "将网站链接复制后，在钱包浏览器中打开进行铸造"
}
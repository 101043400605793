<template>
  <div id="h5Container" >
    <div class="header_view">
      <img src="./../../assets/images/logoicon.png" class="headerLogo"  @click="clickTab(0)"/>
      <div class="headerRight" >
        
        <p :class="tab==1 ? 'choose' : 'notchoose'" @click="scrollPage('one')">{{$t("lang.zhuzao")}}</p>
        <p :class="tab==2 ? 'choose' : 'notchoose'" @click="scrollPage('two')">MK</p>
        <p :class="tab==3 ? 'choose' : 'notchoose'" @click="scrollPage('three')">{{$t("lang.luxiantu")}}</p>
        <p :class="tab==4 ? 'choose' : 'notchoose'" @click="scrollPage('four')">{{$t("lang.tuandui")}}</p>
      </div>
    </div>
    <div class="router_view" >
      <router-view @openLinkWallet="openDialog" :scrollEle="scrollele" @setScrollEle="clickTab" @scrollPage="scrollPage"/>  
      <div class="bottom_link">
        <div class="bottom_link_left">
          <p class="txt1">Token Type：ERC-721A</p>
          <p class="txt1">Blockchain：Ethereum</p>
          <p class="txt1 marginbotom">File Hosting：IPFS</p>
        </div>
        <div>
          <div class="footer_link">
            <a href="https://twitter.com/MKclubHQ?s=09" target="_blank">
              <img src="./../../assets/images/footer_icon1.png" alt="" style="width: 12px;height: 11px">
            </a>
            <!-- <img src="./../../assets/images/footer_icon2.png" alt="" style="width: 16px;height: 11px"> -->
            <a href="https://discord.gg/YBd7VqEA6H" target="_blank">
            <img src="./../../assets/images/footer_icon3.png" alt="" style="width: 14px;height: 11px">
            </a>
            <!-- <img src="./../../assets/images/footer_icon4.png" alt="" style="width: 11px;height: 12px"> -->

           
          </div>
          <p class="copyRight">© 2022 MK LABS</p>
        </div>
      </div>
    </div>

    <div class="footer_bottom">
      <a @click="openInvite" class="footer_bottom_icon">
        <img src="../../assets/images/phonebottomlogo1.png" alt="" style="width:28px">
      </a>
      <div class="wallet_view">
        <div class="link_wallet" v-if="!$store.state.userAddress" @click="openDialog">
          <p>{{connecting ? 'loading' :$t("lang.lianjieqianbao")}}</p>
        </div>
        <div class="link_wallet" v-else   style="min-width: 88px" @click="$router.push('h5mynft')">
          <p>{{$store.state.userAddress.slice(0,4)}}...{{$store.state.userAddress.slice(-4)}}</p>
          <p style="margin-left:19px">MY NFT</p>
        </div>
      </div>
      <div>
        <div class="footer_bottom_icon">
          <img src="../../assets/images/lang_icon.png" alt=""   @click="showLang = !showLang">
        </div>
        <div class="lang_select_bg" v-if="showLang" @click="showLang=false"></div>
        <div class="lang_select" v-if="showLang">
          <div class="lang_select_item" v-for="(item,index) in langList" :key="index" @click="changeLang(item)">
            <p :style="{color: item.value === langSelectValue?'#fff':'#979797'}">{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>
   
    <!-- 链接钱包弹窗 -->
    <div class="connect_wallet_dailog" v-if="showConnectWallet"  @click="closeDialog">
      <div class="connect_wallet">
        <div class="connect_wallet_item">
          <img src="./../../assets/image/fox.png" alt="fox" @click="linkWallet('MetaMask')">
          <p class="connect_wallet_text1">MetaMask</p>
          <p class="connect_wallet_text2">{{$t("lang.lianjiematemask")}}</p>
        </div>
        <!-- <div class="connect_wallet_item">
          <img src="./../../assets/image/wallet.png" alt="fox" @click="linkWallet('WalletConnect')">
          <p class="connect_wallet_text1">WalletConnect</p>
          <p class="connect_wallet_text2">{{$t("lang.lianjieWalletConnect")}}</p>
        </div> -->
      </div>
    </div>


    <H5Modal v-if="modalStatus" @closeModal="closeModal" :title="modalTitle">
    <!-- <p>Switch to the Ethereum NetWork</p> -->
      <div slot="content" v-if="modalType==1" class="switchContent_phone">
        <p class="switchNetwork_text">{{$t("lang.qingqiehuanwang")}}</p>
        <p class="switchNetwork_btn" @click="changeNetwork">{{$t("lang.qiehuanwangluo")}}</p>
      </div>
      <div slot="content" v-if="modalType==2" class="switchContent_phone">
        <p class="switchNetwork_text">连接请求被拒绝</p>
        <p class="switchNetwork_btn" @click="closeModal">关闭</p>
      </div>
      <div slot="content" v-if="modalType==3" class="switchContent_phone">
        
        <div class="copyContent_phone">
          <img src="../../assets/images/inviteperson.png" alt="" class="invitePerson">
          <div>
            <span>https://mkclub.io/#/h5home?from={{this.dealAddr()}}</span>
            <img src="../../assets/images/copyfill.png" alt="" @click="copyUrl">
          </div>
        </div>
        <p class="switchNetwork_btn" style="background:#000000" @click="copyUrl">{{$t("lang.fuzhi")}}</p>
      </div>
    </H5Modal>

    <!-- 没有钱包环境的弹窗 -->
    <div class="noWalletView" v-show="noWallet" @click="noWallet=false">
      <div class="noWalletContent">
        <p class="noWalletTitle">{{$t("lang.dakaiqianbao")}}</p>
        <div class="noWalletImg">
          <img src="./../../assets/image/fox.png" alt="">
          <img src="./../../assets/image/imtoken.png" alt="">
          <img src="./../../assets/image/TP.png" alt="">
        </div>
        <p class="noWalletTips">{{$t("lang.lianjiefuzhi")}}</p>
      </div>
    </div> 

  </div>
</template>

<script>
import Vue from 'vue';
import Web3 from "web3";
import H5Modal from "@/components/H5Modal.vue";
export default {
  name: "h5home",
  components: {
    H5Modal
  },
  data() {
    return {
      tab: 1, // 控制顶部导航选中状态
      showMenu: false,
      showDialog: false,
      langList: [
        {name: 'English', value: 'en'},
        {name: '简体中文', value: 'zh'},
        {name: '日本语', value: 'jp'},
        {name: '한국어', value: 'ko'},
        {name: '繁体中文', value: 'zhtw'},
      ],
      langSelectValue: '',
      showLang: false,

      showConnectWallet: false, // 连接钱包弹窗
      showDraw: false, // 右侧个人中心
      modalStatus: false, // 弹窗显示
      modalType: 1, // 弹窗内容类型
      modalTitle: '', // 弹窗标题
      connecting: false, // 连接钱包loading

      scrollele: 'one',

      noWallet: false, // 无钱包弹窗
    };
  },
  created() {
    this.langSelectValue = this.$i18n.locale;
  },
  watch: {
   "$route.name"() {
    console.log(this.$route.name)
      const routeName = this.$route.name;
      if(routeName == 'h5mint' || routeName == 'h5mynft') {
        this.tab = 10;
      }
   }
  },
  mounted() {
    this.linkWallet('MetaMask')
    this.getRoute();
  },
  methods: {
    getRoute() {
      const routeName = this.$route.name;
      if(routeName == 'h5mint' || routeName == 'h5mynft') {
        this.tab = 10;
      }
    },
    scrollPage (url) {
      const routeName = this.$route.name;
      if(routeName == 'h5mint' || routeName == 'h5mynft') {
        sessionStorage.setItem('tabvalh5',url)
        this.$router.replace('h5home');
        return 
      }
      let anchorElement = document.getElementById(url);
      console.log(anchorElement)
      if(anchorElement) { 
        anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }
    },
   
    clickTab(val) {
      const routeName = this.$route.name;
      console.log(routeName)
      if(routeName == 'h5mint' || routeName == 'h5mynft') {
        sessionStorage.setItem('tabvalh5',val)
        this.$router.replace('h5home');
        return 
      }
      this.tab = val;
    },
    openMenu() {
      this.showMenu = true
    },
    closeMenu(e) {
      if(e.target.className == 'draw_menu_view') {
        this.showMenu = false;
      }
    },
    goPage(route,value) {
      this.showMenu = false;
      if(value) {
        this.$router.push({
          path: route,
          query: { type: value}
        });
        return 
      }
      this.$router.push(route);
    },
    changeLang(item) {
      this.langSelectValue = item.value;
      this.$i18n.locale = item.value;
      this.showLang = false;
    },
    closeModal() {
      this.modalStatus = false;
      this.modalTitle = '';
    },
    changeNetwork() {
      this.$utils.switchNetwork(this.$api.chainId)
    },
    goNft() {
      this.tab = 0
      this.$router.push('/h5mynft');
      this.closeDraw();
    },
    closeDialog(e) {
      if(e.target.className == 'connect_wallet_dailog') {
        this.showConnectWallet = false;
      }
    },
    openDialog() {
      if(!window.ethereum) {
        // this.showConnectWallet = false;
        this.noWallet = true;
        // 弹窗提示 安装 钱包
        // this.$alert("No MateMask!")
        return 
      }
      this.showConnectWallet = true;
    },
    openDraw() {
      this.showDraw = true;
    },
    closeDraw() {
      this.showDraw = false;
    },
    clickDrwaBg(e) {
      if(e.target.className == 'wallet_info_draw') {
        this.showDraw = false;
      }
    },
    disconnect() {
      this.showConnectWallet = false;
      this.address = '';
      sessionStorage.clear();
      this.$store.commit('setUseraddress',null);
      this.closeDraw();
    },
    linkWallet(type) {
      if(type == "MetaMask") {
        if(!window.ethereum) {
          // this.showConnectWallet = false;
          this.noWallet = true;
          // 弹窗提示 安装 钱包
          // this.$alert("No MateMask!")
          return 
        }
      }
      this.connecting = true;
      this.$utils.linkWallet({
        type,
        successFn: async (data)=>{
          // this.address = data[0];
          
          this.showConnectWallet = false;
          const web3Object = new Web3(window.ethereum);
          const chainId = await web3Object.eth.getChainId();
          if(chainId != this.$api.chainId) {
            this.showConnectWallet = false;
            this.connecting = false;
            if(type=='MetaMask') {
              this.modalType = 1;
              this.modalTitle = this.$t("lang.wanluocuowu");
              this.modalStatus = true;
              sessionStorage.setItem('walletType',type);
            } else {
              this.$alert('Please switch wallet network', 'Network Error!');
            }
            return 
          }
          
          Vue.prototype.web3js = web3Object;
          this.showConnectWallet = false;
          sessionStorage.setItem('walletType',type);
          const params = {
            web3js: web3Object,
            userAddr: data[0]
          }
          this.$store.commit('setUseraddress',data[0])
          this.$store.dispatch("initContartInfo", params);
          this.connecting = false;
          
          // sessionStorage.getItem('',)
        },
        errorFn: ()=>{
          // Message
          this.showConnectWallet = false;
          this.modalType = 2;
          this.modalTitle = "连接失败";
          this.modalStatus = true;
          this.connecting = false;
        }
      });
    },

    dealAddr() {
      const addr = this.$store.state.userAddress;
      const str = addr.slice(2,addr.length)
      if(!addr) {
        return ''
      } 
      return '0x'+str.slice(0,4)+str.slice(-4)
    },
    openInvite() {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      this.modalTitle = this.$t("lang.yaoqinglianjie")
      this.modalStatus = true;
      this.modalType = 3;
    },
    copyUrl() {
      const Url2 = `https://mkclub.io/#/h5home?from=${this.dealAddr()}`
      var input = document.createElement('input'); // 创建input标签，只有input标签选中可以
      input.setAttribute('id', 'copyInput'); // 给input一个id属性
      input.setAttribute('value', Url2); // 给input一个value属性，属性值是变量span的文本
      document.getElementsByTagName('body')[0].appendChild(input); // body里面添加input实体
      document.getElementById('copyInput').select(); // 使用js去通过id找到并执行input实体的全部选中
      document.execCommand("Copy"); //原生copy方法执行浏览器复制命令
      document.getElementById('copyInput').remove(); // 为避免下次页面添加copy方法 所以这里copy完之后要移除input实体
      this.$message({
      type: 'success',
      message: this.$t("lang.fuzhichenggong")
      })
    },
  },
  
};
</script>

<style lang="less">
#h5Container {
  width: 100%;
  min-height: 100vh;
  background: #000000;
  box-sizing: border-box;
  padding-top: 60px;
  .header_view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 29px;
    background: #000;
    z-index: 9;
    padding-bottom: 5px;
    .headerLogo {
      width: 85px;
      flex-shrink: 0;
    }
    .headerRight {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        margin-left: 22px;
        height: 30px;
        box-sizing: border-box;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 3px;
        &:first-child {
          margin: 0;
        }
      }
      .notchoose {
        color: #979797;
      }
      .choose {
        color: #FFFFFF;
      }
    }
  }


  .footer_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 67px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer_bottom_icon {
      width: 25px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      img {
        width: 25px;
        height: auto;
      }
    }
    .wallet_view {
      margin: 0 35px;
      width: 189px;
      height: 39px;
      background: url('./../../assets/images/phonelinkwalletbg.png') no-repeat;
      background-size: 100% 100%;
      border-radius: 1px;
      display: flex;
      align-items: center;
      justify-content: center;

      .link_wallet {
        min-width: 115px;
        padding: 0 10px;
        box-sizing: border-box;
        height: 33px;
        border-radius: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
      }
      .link_wallet img {
        width: 15px;
        height: auto;
        margin-right: 10px;
      }
      .link_wallet p {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
  .lang_select_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
  }
  .lang_select {
    position: fixed;
    bottom: 67px;
    right: 23px;
    width: 150px;
    height: 280px;
    background: #000;
    border-radius: 5px;
    box-sizing: border-box;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .lang_select_item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      text-align: center;
      p {
        width: 100%;
        color: #fff;
        font-size: 12px;
        &:active {
          background: #fff;
        }
      }
    }
    .lang_select_item 
    .lang_select_item p {
      
    }
    
  }
  
}



.router_view {
  box-sizing: border-box;
  padding-bottom: 67px;
  
  .bottom_link {
    border-top: 1px solid #0033FF;
    border-bottom: 19px solid #0033FF;
    padding-top: 56px;
    display: flex;
    justify-content: space-between;
    .bottom_link_left {
      padding-left: 29px;
      p {
        font-size: 10px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 17px;
        margin: 0;
      }
    }
    .footer_link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 29px;
      a {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .copyRight {
      padding-right: 29px;
      text-align: right;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9A9A9A;
      line-height: 23px;
      margin-top: 20px;
      margin-bottom: 58px;
    }
    
  }
}


.connect_wallet_dailog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 38px;
  box-sizing: border-box;
}
.connect_wallet {
  width: 100%;
  height: 240px;
  background: #FFFFFF;
  border-radius: 19px;
  display: flex;
  align-items: center;
}
.connect_wallet_item {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .connect_wallet_item:first-child {
  border-bottom: 1px solid #E1EDE8;
  box-sizing: border-box;
} */
.connect_wallet_item img {
  width: 38px;
}
.connect_wallet_text1 {
  font-size: 17px;
  font-weight: 500;
  color: #26493E;
  line-height: 24px;
  margin: 5px 0;
}
.connect_wallet_text2 {
  font-size: 12px;
  font-weight: 500;
  color: #93BCAF;
}
.switchContent_phone {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .switchNetwork_text {
    flex: 1;
    font-size: 13px;
    color: #333;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .switchNetwork_btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(270deg, #0061FF 0%, #0033FF 100%);
    border-radius: 1px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    margin: auto;
    cursor: pointer;
    margin-bottom: 20px;
  }
}
.wallet_info_draw {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.draw_view {
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
  height: 100%;
  background: #FFFFFF;
  padding: 0 15px;
  padding-top: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;
  overflow: auto;
}
.draw_title {
  font-size: 17px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #769991;
  margin-bottom: 10px;
  padding-left: 10px;
}
.draw_dollar {
  font-size: 27px;
  height: 27px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #26493E;
  margin-bottom: 1.5rem;
}
.draw_coin {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.draw_coin_icon {
  width: 22px;
  height: 22px;
  margin-right: 22px;
}
.draw_coin_icon img {
  height: 22px;
  width: auto;
}
.draw_coin_name {
  text-align: left;
  flex: 1;
  font-size: 13px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #26493E;
}
.draw_coin_value {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #26493E;
  line-height: 20px;
  text-align: right;
}
.my_nft_btn {
  width: 100%;
  height: 40px;
  background: url('./../../assets/image/btn_bg.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  cursor: pointer;
}
.my_nft_btn p {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
.my_nft_btn img {
  width: 12px;
}

.close_draw {
  position: absolute;
  top: 20px;
  right: 17px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.close_draw img {
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
}
.draw_disconnect {
  position: absolute;
  bottom: 48px;
  left: 20%;
  width: 80%;
  height: 20px;
  font-size: 17px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #9A9A9A;
  text-align: center;
  cursor: pointer;
}


.copyContent_phone {
  width: 100%;
  box-sizing: border-box;
  .invitePerson {
    width: 67px;
    height: 67px;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 60px;
  }
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    span {
      font-size: 12px;
      color: #4A4A4A;
      font-weight: 400;
      text-align: left;
      padding: 0;
      margin-right: 16px;
      word-break: break-all;
    }
    img {
      width: 16px;
      height: auto;
      cursor: pointer;
    }
  }
}

.noWalletView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  box-sizing: border-box;
  z-index: 999;
  .noWalletContent {
    width: 100%;
    height: 200px;
    background: #FFFFFF;
    padding-top: 13px;
    padding-bottom: 31px;
  }
  .noWalletTitle {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000;
    text-align: center;
    margin-bottom: 38px;
    }
  .noWalletImg {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 25px;
  }
  .noWalletImg img {
    width: 58px;
    height: 58px;
  }
  .noWalletTips {
    font-size: 11px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #979797;
    text-align: center;
    padding: 0 28px;
    
  }
}
</style>

<template>
  <div id="indexPage" @click="$router.push('/home')">
    <img src="./../../assets/images/banner0.png" alt="">
  </div>
</template>
<script>
export default {
  name: "index",
  components: {
  },
  data() {
    return {
      
    };
  },
 
};
</script>

<style lang="less">
#indexPage {
  width: 100%;
  img {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
}
</style>
